import React from 'react'
import styled from 'styled-components/native'
import { View, ImageBackground, StyleSheet } from 'react-native'

import useIsFullScreenWeb from '../../hooks/useIsFullScreenWeb'
// @ts-ignore
import HeaderBg from '../../../assets/images/headerBg.png'

const HeroContainer = styled(View)`
  width: 100%;
  background-position: center;
  background-attachment: fixed;
  position: absolute;
  background-size: cover;
  ${({ isFullScreenWeb, theme: { colors } }) => `
    height: ${isFullScreenWeb ? '45%' : '75%'};
    background-color: ${colors.imageBackgroundColor};
  `}
`

const s = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  }
})

const SharedContentTemplate = () => {
  const isFullScreenWeb = useIsFullScreenWeb()
  return (
    <>
      <HeroContainer isFullScreenWeb={isFullScreenWeb}>
        <ImageBackground
          resizeMode="cover"
          source={{ uri: HeaderBg }}
          style={s.backgroundImage}
        />
      </HeroContainer>
    </>
  )
}

interface SharedContentTemplateProps {
  data?: any
  children?: any
}

export default SharedContentTemplate
