import React from 'react'
import styled from 'styled-components/native'
import { View, ViewStyle } from 'react-native'
import { StyledProps } from 'styled-components'
import { Card as ElementCard } from 'react-native-elements'

// Components
import * as Text from '../../components/core/text'

export const CardTitle = styled.Text`
  width: 100%;
  font-family: Inter
  text-transform: capitalize;
  ${({ theme: { colors, fontSizes, fontWeights, space } }) => `
    line-height: ${space[4] + 2}px;
    color: ${colors.newWorld50Black};
    font-size: ${fontSizes[4] + 2}px;
    font-weight: ${fontWeights.bold};
    margin-bottom: ${space[2]}px;;
  `}
`

export const CardText = styled(Text.Body1)`
  font-family: Inter;
  ${({ theme: { fontSizes, fontWeights, colors } }) => `
    font-size: ${fontSizes[4] - 2}px;
    font-weight: ${fontWeights.regular};
    color: ${colors.newWorld50Black};
  `};
`

export interface ElementCardProps {
  style?: StyledProps<ViewStyle>
  containerStyle?: StyledProps<ViewStyle>
  wrapperStyle?: StyledProps<ViewStyle>
  children?: React.ReactElement<any>
  title?: string | null
  bodyText?: string | null
}

export const Card = ({
  children,
  style,
  containerStyle,
  title = null,
  bodyText = null,
  ...rest
}: ElementCardProps) => {
  return (
    <View style={style}>
      <ElementCard
        containerStyle={{
          margin: 0,
          padding: 0,
          borderRadius: 15,
          ...containerStyle
        }}
        {...rest}
      >
        {title && <CardTitle>{title}</CardTitle>}
        {bodyText && <CardText>{bodyText}</CardText>}
        {children}
      </ElementCard>
    </View>
  )
}
