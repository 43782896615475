import React, {
  Fragment,
  useState,
  useMemo,
  useContext,
  useEffect
} from 'react'
import { Linking, ScrollView } from 'react-native'
import styled from 'styled-components/native'
import { RouteProp, useRoute } from '@react-navigation/native'
// Hooks
import useDocumentItemQuery from '../../hooks/useDocumentItemQuery'
import useTranslation from '../../hooks/useTranslation'
import useIsFullScreenWeb from '../../hooks/useIsFullScreenWeb'
import useFileStats from '../../hooks/useFileStats'
//  Container/Components
import {
  BodyCard,
  BodyColumn,
  CardsContainer,
  DescriptionContainer,
  HeaderContainer,
  InfoCard,
  ItemHeader,
  RightColumn,
  SubTitle,
  Title,
  ConfidentialLabel
} from '../components'
import * as Text from '../../components/core/text'
import DownloadButton from './components/DownloadButton'
import CloudinaryImage from '../../components/CloudinaryImage'
import DraftJSRenderer from '../../components/DraftJSRenderer'
import PersonTagList from '../../components/personTag/PersonTagList'
import EntityTopicFooter from '../../components/topics/EntityTopicFooter'
import FullScreenWebLayout from '../../components/layout/FullScreenWebLayout'
import SharedContentTemplate from '../../components/layout/SharedContentTemplate'
import { ScreenContainerWithItemHeader } from '../../components/layout/ScreenContainer'
import Copyright from '../../components/common/Copyright'
// Lib
import downloadFile from '../../lib/Attacher/downloadFile'
import idToCloudinaryUrl from '../../lib/idToCloudinaryUrl'
// Types
import { ContentTypeEnum, RootStackParamList } from '../../types'
// Context
import { RegistrationStateContext } from '../../AppRoot/providers/UserRegistrationProvider'

// styled-components
export const TitleHeader = styled(ItemHeader)`
  ${({ isFullScreenWeb, theme: { space } }) => `
    margin-top: ${!isFullScreenWeb ? space[2] : 0}px;
    margin-bottom: ${!isFullScreenWeb ? space[3] + 8 : 0}px;
    padding-horizontal: ${!isFullScreenWeb ? space[3] : 0}px;
  `}
`

const ImageContainer = styled(CloudinaryImage).attrs(() => ({}))`
  ${({ isFullScreenWeb, theme: { sizes } }) => `
    min-height: ${
      isFullScreenWeb ? sizes[7] + sizes[3] : sizes[6] + sizes[4]
    }px;
    width: 100%;
  `}
`

const DocumentItemScreen = () => {
  // Hooks
  const { onContentAdded, registrationLoading } = useContext(
    RegistrationStateContext
  )
  const { t } = useTranslation()
  const route = useRoute<MediaItemRouteProp>()
  const id = route.params?.id
  const { document, loading } = useDocumentItemQuery(id)
  const [blob] = useState<Blob>()
  const { bytesToSize, getFileExtension } = useFileStats()
  const isFullScreenWeb = useIsFullScreenWeb()
  const isDataLoading = loading || !document?.id

  // Effects
  useEffect(() => {
    if (document?.id && !isDataLoading) {
      onContentAdded({
        contentId: document.id,
        contentTitle: document.title,
        contentTopics: document.topics,
        contentType: ContentTypeEnum.document
      })
    }
  }, [document, isDataLoading])

  // Render
  const body = useMemo(() => {
    if (isDataLoading) return null
    if (document.body) {
      try {
        return JSON.parse(document.body)
      } catch {
        console.log('Could not parse document body. Rendering as plain text.')
      }
    }

    return document.body
  }, [document, isDataLoading])

  const { file } = document || {}
  const fileFormat = getFileExtension(file?.fileName)
  const fileSize = bytesToSize(blob?.size)

  const onViewFile = async () => {
    if (fileFormat === 'pdf') {
      await Linking.openURL(
        idToCloudinaryUrl(file.cloudinaryId, { resource_type: 'raw' })
      )
    } else {
      await downloadFile(
        idToCloudinaryUrl(file.cloudinaryId, { resource_type: 'raw' }),
        file.fileName
      )
    }
  }

  if (isDataLoading || registrationLoading) {
    return null
  }

  const Wrapper = isFullScreenWeb ? FullScreenWebLayout : Fragment

  return (
    <ScreenContainerWithItemHeader
      webTitle={document.title}
      screenCategory="document"
    >
      <SharedContentTemplate />
      <ScrollView>
        <Wrapper>
          {/* Header */}
          <HeaderContainer flexDirection={isFullScreenWeb ? 'row' : 'column'}>
            <TitleHeader isFullScreenWeb={isFullScreenWeb}>
              <Title isFullScreenWeb={isFullScreenWeb}>{document.title}</Title>
              <SubTitle isFullScreenWeb={isFullScreenWeb}>
                {document.subtitle}
              </SubTitle>
              {document?.personTags?.length ? (
                <PersonTagList personTags={document.personTags} />
              ) : null}
              <EntityTopicFooter topics={document.topics} wrapPills />
              <ConfidentialLabel>{t('common:confidential')}</ConfidentialLabel>
            </TitleHeader>
            {document.image && (
              <ItemHeader isFullScreenWeb={isFullScreenWeb}>
                <ImageContainer
                  publicId={document.image.cloudinaryId}
                  resizeMode={isFullScreenWeb ? 'cover' : 'contain'}
                  isFullScreenWeb={isFullScreenWeb}
                />
              </ItemHeader>
            )}
          </HeaderContainer>
          {/* Cards */}
          <CardsContainer isFullScreenWeb={isFullScreenWeb}>
            {/* Left Column */}
            <BodyColumn isFullScreenWeb={isFullScreenWeb}>
              <BodyCard>
                {file ? (
                  <DownloadButton
                    onClick={onViewFile}
                    isFullScreenWeb={isFullScreenWeb}
                    text={`${t(
                      'common:download'
                    )} ${fileFormat?.toUpperCase()}`}
                    label={fileSize.toLowerCase()}
                  />
                ) : null}
                <DescriptionContainer isFullScreenWeb={isFullScreenWeb}>
                  {typeof body === 'string' ? (
                    <Text.Body1>{body}</Text.Body1>
                  ) : (
                    <DraftJSRenderer value={body} />
                  )}
                </DescriptionContainer>
              </BodyCard>
            </BodyColumn>
            {/* Right column */}
            <RightColumn isFullScreenWeb={isFullScreenWeb}>
              <InfoCard
                title={t('common:promo:title')}
                bodyText={t('common:promo:description')}
              />
            </RightColumn>
            <Copyright />
          </CardsContainer>
        </Wrapper>
      </ScrollView>
    </ScreenContainerWithItemHeader>
  )
}

type MediaItemRouteProp = RouteProp<RootStackParamList, 'MediaItem'>
export default DocumentItemScreen
