import React from 'react'
import styled from 'styled-components/native'
import useTranslation from '../../hooks/useTranslation'
import useIsFullScreenWeb from '../../hooks/useIsFullScreenWeb'

const AgreementsContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  ${({ isFullScreenWeb, theme: { space } }) => `   
    margin-top: ${isFullScreenWeb ? space[2] - 1 : 0}px;  
`}
`

const AgreementsCard = styled.Text`
  font-family: 'Inter';
  ${({ theme: { fontSizes, space, fontWeights, colors } }) => `   
    font-weight: ${fontWeights.regular}
    font-size: ${fontSizes[1] + 1}px;  
    line-height: ${space[3]}px;
    margin-top: ${space[0] - 5}px;
    margin-bottom: ${space[1] - 2}px;
    color: ${colors.gray7};
  `}
`
const TextLink = styled.Text`
  font-family: 'Inter';
  font-style: italic;
  ${({ theme: { fontSizes, space, fontWeights, colors } }) => `   
    font-weight: ${fontWeights.regular};
    font-size: ${fontSizes[1] + 1}px;  
    line-height: ${space[3]}px;
    margin-top: ${space[0] - 5}px;
    margin-bottom: ${space[1] - 2}px;
    color: ${colors.enabled};
  `}
`

const UserRegistrationAgreements: React.FC<UserRegistrationAgreementsProps> = () => {
  const { t } = useTranslation()
  const isFullScreenWeb = useIsFullScreenWeb()

  return (
    <AgreementsContainer isFullScreenWeb={isFullScreenWeb}>
      <AgreementsCard>
        {t('userRegistration:legal:agreementBeginning')}
      </AgreementsCard>
      <TextLink
        onPress={() =>
          window.open('https://www.world50.com/legal/terms-of-use')
        }
      >
        {t('userRegistration:legal:termsLabel')}
      </TextLink>
      <TextLink
        onPress={() =>
          window.open('https://www.world50.com/legal/privacy-policy')
        }
      >
        {t('userRegistration:legal:privacyLabel')}
      </TextLink>
      <AgreementsCard>
        {t('userRegistration:legal:agreementEnd')}
      </AgreementsCard>
      <TextLink
        onPress={() =>
          window.open(
            'https://www.world50.com/legal/privacy-policy#cookies-and-other-technologies'
          )
        }
      >
        {t('userRegistration:legal:cookieLabel')}
      </TextLink>
    </AgreementsContainer>
  )
}

interface UserRegistrationAgreementsProps {}

export default UserRegistrationAgreements
