import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { useRecoilState } from 'recoil'
import toastMessageAtom from '../recoil/atoms/toastMessageAtom'
import styled from 'styled-components'

export default function ToastAnchor() {
  let [toastMessage, setToastMessage] = useRecoilState(toastMessageAtom)

  const hasMessage = !!toastMessage

  return (
    <ToastSlider style={{ height: hasMessage ? 66 : 0 }}>
      {hasMessage && (
        <ToastContainer>
          <ToastText>{toastMessage}</ToastText>
          <TouchableOpacity onPress={() => setToastMessage('')}>
            <ToastText>×</ToastText>
          </TouchableOpacity>
        </ToastContainer>
      )}
    </ToastSlider>
  )
}

const ToastSlider = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
  transition: height 0.2s ease-out;
  border-bottom-width: 1px;
  overflow: hidden;
  background: #85adc3;
`
const ToastContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`
const ToastText = styled(Text)`
  color: #fff;
  font-size: 22px;
`
