import React, { ReactNode } from 'react'
import styled from 'styled-components/native'
import { Platform, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import { useNavigation, DrawerActions } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { navActionFromScreenName } from '../../hooks/useLinkToScreen'
import MenuIcon from '../icon/MenuIcon'
import Logo from '../icon/Logo'
import ArrowLeftIcon from '../icon/ArrowLeftIcon'

import { NavigationProp } from '@react-navigation/core/lib/typescript/src/types'
import { ParamListBase } from '@react-navigation/routers'

export interface HeaderProps {
  BackButton: React.ComponentType<BackButtonProps>
}

export interface BackButtonProps {
  defaultBack?: string
  style?: ViewStyle
  inverted?: boolean
  navigation?: NavigationProp<ParamListBase>
  onBackPress?: Function
}

interface MenuHeaderContentProps {
  pageTitle?: string
  headerLogo?: boolean
}

interface ItemHeaderContentProps {
  pageTitle?: string
  HeaderRight?: React.ComponentType
  renderedHeaderRight?: ReactNode
  defaultBack?: string
  onBackPress?: Function
  navigation?: NavigationProp<ParamListBase>
}

interface CustomHeaderContentProps {
  Header?: React.ComponentType<HeaderProps>
  headerProps?: any
  navigation?: NavigationProp<ParamListBase>
  onBackPress?: Function
}

export function goBack(navigation, defaultBack = 'Media') {
  if (navigation.canGoBack()) {
    navigation.goBack()
  } else {
    // TODO: would be best if we could find a way to pass in
    // the "current" screen name to the relativeToScreenName
    // param here
    const navAction = navActionFromScreenName(defaultBack)
    navigation.navigate(navAction.screen, navAction.params)
  }
}

const BackButton = ({
  defaultBack = 'Media',
  inverted = false,
  navigation,
  style,
  onBackPress
}: BackButtonProps) => {
  onBackPress = onBackPress ?? (() => goBack(navigation, defaultBack))

  return (
    <HeaderTouchable onPress={onBackPress}>
      <StyledArrowLeftIcon style={style} inverted={inverted} />
    </HeaderTouchable>
  )
}

export const MenuHeaderContent = () => {
  const navigation = useNavigation()
  const onClickHamburger = () => navigation.dispatch(DrawerActions.openDrawer())

  return (
    <HeaderContentWrap testID={'headerContentWrap'}>
      <LeftContainer testID={'headerLeftContainer'}>
        <HeaderTouchable testID={'headerMenu'} onPress={onClickHamburger}>
          <StyledMenuIcon />
        </HeaderTouchable>
      </LeftContainer>
      <MiddleContainer testID={'headerLogo'}>
        <StyledLogo />
      </MiddleContainer>
      <RightContainer />
    </HeaderContentWrap>
  )
}

export const ItemHeaderContent = ({
  HeaderRight,
  renderedHeaderRight
}: ItemHeaderContentProps) => {
  let rightContent: ReactNode = null
  if (HeaderRight) {
    rightContent = <HeaderRight />
  } else if (renderedHeaderRight) {
    rightContent = renderedHeaderRight
  }
  return (
    <HeaderContentWrap>
      <StyledLogo />
      <LeftContainer />
      <MiddleContainer>
        <HeaderTouchable />
      </MiddleContainer>
      <RightContainer>{rightContent}</RightContainer>
    </HeaderContentWrap>
  )
}

const curryProps = (curryProps, Component) => props => (
  <Component {...curryProps} {...props} />
)

export const CustomHeaderContent = ({
  Header,
  headerProps = {},
  navigation,
  onBackPress
}: CustomHeaderContentProps) => {
  return (
    <HeaderContentWrap style={{ height: 'auto' }}>
      {Header && (
        <Header
          BackButton={curryProps({ navigation, onBackPress }, BackButton)}
          {...headerProps}
        />
      )}
    </HeaderContentWrap>
  )
}

export const HeaderContentWrap = styled(View).attrs(({ testID }) => ({
  testID
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  height: 54px;
  ${Platform.OS === 'web' && 'user-select: none;'}
`
export const HeaderContainer = styled(SafeAreaView).attrs({
  edges: ['top']
})`
  z-index: 1000;
`

export const MenuHeaderContainer = styled(HeaderContainer)`
  width: 100%;
  background-color: ${props => props.theme.colors.heroBackground};
`
export const ItemHeaderContainer = styled(HeaderContainer)`
  width: 100%;
  background: ${props => props.theme.colors.heroBackground};
`
export const CustomHeaderContainer = styled(HeaderContainer)`
  width: 100%;
  background: ${props => props.theme.colors.heroBackground};
`
const SideContainer = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  min-height: 43px;
`
const LeftContainer = styled(SideContainer).attrs(({ testID }) => ({ testID }))`
  justify-content: flex-start;
`
const RightContainer = styled(SideContainer)`
  justify-content: flex-end;
`
const MiddleContainer = styled(View)`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 2;
  align-items: center;
  justify-content: center;
`
export const HeaderTouchable = styled(TouchableOpacity).attrs(({ testID }) => ({
  testID
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${props => props.theme.space[2] + props.theme.space[1]}px;
  padding-right: ${props => props.theme.space[2] + props.theme.space[1]}px;
`
const ScreenTitle = styled(Text).attrs({ numberOfLines: 1 })`
  color: ${props => props.theme.colors.white};
  font-size: 20px;
  font-weight: bold;
`
export const ItemHeaderScreenTitle = styled(ScreenTitle)`
  color: ${props => props.theme.colors.primary};
`

const StyledMenuIcon = styled(MenuIcon).attrs(props => ({
  size: 24,
  color: '#fff',
  opacity: 0.75,
  testID: props.testID
}))``

const StyledLogo = styled(Logo)`
  padding-left: ${props => props.theme.space[3]}px;
`

const StyledArrowLeftIcon = styled(ArrowLeftIcon).attrs(props => ({
  size: 24,
  color: props.inverted ? '#000' : '#fff'
}))``
