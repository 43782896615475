import { useEffect } from 'react'
import gql from 'graphql-tag'
import Constants from 'expo-constants'
import mixpanel from 'mixpanel-browser'
import { useQuery } from '@apollo/client'
// Types
import { ContentState } from '../AppRoot/providers/UserRegistrationProvider'
import { ContentTypeEnum } from '../types'

export const configQuery = gql`
  query config($key: String!) {
    config(key: $key)
  }
`

export const AppId = 'shared-content'

export enum TrackEvents {
  SharedMediaView = 'Shared Media View',
  SharedDocumentView = 'Shared Document View'
}

let disabled: boolean = true

const useMixpanel = () => {
  const { MIXPANEL_TOKEN } = Constants.manifest.extra

  const { data } = useQuery(configQuery, {
    variables: { key: 'mixpanelDisabled' }
  })

  useEffect(() => {
    disabled = data?.config
  }, [data?.config])

  mixpanel.init(MIXPANEL_TOKEN, { debug: !!Constants.debugMode })

  // Track functions
  const trackWithProperties = async (
    event: TrackEvents,
    properties: Record<string, unknown>
  ) => {
    if (disabled || disabled === undefined) return

    // Default Properties
    properties.appId = AppId

    mixpanel.track(event, properties)
  }

  // Identify, i.e. associate to an existing mixpanel profile
  const identify = async (user: string) => {
    if (disabled || disabled === undefined) return
    mixpanel.identify(user)
  }

  // Track Content view
  const trackContentView = (contentState: ContentState) => {
    const topics = contentState?.contentTopics
    let params = {
      contentId: contentState?.contentId,
      contentType: contentState?.contentType,
      contentTitle: contentState?.contentTitle,
      topics: topics && topics.length > 0 ? topics.map(t => t.name) : null,
      topicIds: topics && topics.length > 0 ? topics.map(t => t.id) : null
    }

    const cleanParams = clean(params)

    trackWithProperties(
      contentState.contentType === ContentTypeEnum.document
        ? TrackEvents.SharedDocumentView
        : TrackEvents.SharedMediaView,
      cleanParams
    )
  }

  const clean = (obj: any) => {
    Object.keys(obj).forEach(key => obj[key] === null && delete obj[key])
    return obj
  }

  return {
    trackContentView,
    trackWithProperties,
    identify
  }
}

export default useMixpanel
