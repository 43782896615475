import React, { useState, useEffect, createContext } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
// Hooks
import useMixpanel from '../../hooks/useMixpanel'
import useToggleState from '../../hooks/useToggleState'
import useCanViewSharedContentQuery from '../../hooks/useCanViewSharedContentQuery'
// Components/Types
import { ContentTopic } from '../../components/topics/ContentTopic'
import { ContentTypeEnum } from '../../types'
import UserRegistrationModal from '../../components/registration/UserRegistrationModal'

// Async storage
import { registrationFieldsAsyncKey } from '../../components/registration/SavedRegistrationFields'

const initialValues: RegistrationContextState = {
  registrationLoading: true,
  onContentAdded: () => {}
}

export const RegistrationStateContext = createContext(initialValues)

const UserRegistrationProvider: React.FC<Props> = ({ children }) => {
  // States/Hooks
  const { identify, trackContentView } = useMixpanel()
  const [currentState, setCurrentState] = useState<ContentState>({
    id: '',
    contentTitle: '',
    contentTopics: [],
    contentId: null,
    contentType: null
  })
  const {
    isOn: userRegistrationDialogIsOn,
    turnOn: turnOnUserRegistrationDialog,
    turnOff: turnOffUserRegistrationDialog
  } = useToggleState(false)
  const {
    isOn: registrationLoading,
    turnOff: turnOffRegistrationLoading
  } = useToggleState(true)

  // Query
  const {
    data: fetchData,
    loading: fetchLoading,
    refetch
  } = useCanViewSharedContentQuery(
    currentState.id,
    currentState.contentId,
    currentState.contentType
  )

  // Events
  const onContentAdded = async (values: ContentState) => {
    let contentValues = { ...values }
    const contentStorage = await AsyncStorage.getItem(
      registrationFieldsAsyncKey
    )
    const contentObject = JSON.parse(contentStorage || '{}')
    contentValues.id = contentObject?.id || null
    setCurrentState(contentValues)
  }

  const onValidateCurrentState = () => {
    if (
      !currentState.id ||
      !fetchData ||
      !fetchData?.canRegistrationViewSharedContent
    ) {
      turnOnUserRegistrationDialog()
    } else {
      trackContentView(currentState)
      turnOffUserRegistrationDialog()
    }
    turnOffRegistrationLoading()
  }

  const onCompleteRegister = async (register: any) => {
    if (register?.id) {
      const identityId = register?.userId || register.workEmail
      identify(identityId)
      await AsyncStorage.setItem(
        registrationFieldsAsyncKey,
        JSON.stringify(register)
      )
      setCurrentState({ ...currentState, id: register.id })
      refetch()
    }
  }

  // Effects
  useEffect(() => {
    if (!fetchLoading && currentState.contentId && currentState.contentType) {
      onValidateCurrentState()
    }
  }, [currentState, fetchLoading])

  return (
    <RegistrationStateContext.Provider
      value={{ registrationLoading, ...currentState, onContentAdded }}
    >
      <UserRegistrationModal
        contentId={currentState.contentId || null}
        contentType={currentState.contentType || null}
        isFetchLoading={registrationLoading || fetchLoading}
        isVisible={
          !!currentState.contentId &&
          !!currentState.contentType &&
          userRegistrationDialogIsOn
        }
        onCompleteRegister={onCompleteRegister}
      />
      {children}
    </RegistrationStateContext.Provider>
  )
}

export interface ContentState {
  id?: string | null
  contentId: string | null
  contentTitle: string | null
  contentTopics: ContentTopic[]
  contentType: ContentTypeEnum | null
}
interface RegistrationContextState {
  registrationLoading: boolean
  onContentAdded: (newState: ContentState) => void
}

interface Props {
  children: JSX.Element | JSX.Element[] | null | Boolean
}

export default UserRegistrationProvider
