import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components/native'
import { TextComponentType } from './index'

interface Props {
  segment: any
  onPress: () => void
  style: any
  TextComponent: TextComponentType
}

const SegmentText = ({ segment, onPress, TextComponent, style }: Props) => {
  const { fontWeights } = useContext(ThemeContext as any)

  return (
    <TextComponent
      style={
        {
          fontWeight: segment.bold
            ? `${fontWeights.bold}`
            : `${fontWeights.regular}`,
          fontStyle: segment.italic ? 'italic' : 'normal',
          textDecorationLine:
            segment.underline || segment.entityKey !== null
              ? 'underline'
              : 'none',
          ...style
        } as any // Typescript complains about fontWeights being strings but react-native requires strings.
      }
      onPress={onPress}
    >
      {segment.text}
    </TextComponent>
  )
}

export default SegmentText
