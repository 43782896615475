import { Provider } from 'react-native-paper'
import { Platform } from 'react-native'
import styled from 'styled-components/native'
import { rgba } from 'polished'

const PaperProvider = styled(Provider).attrs(props => ({
  theme: {
    colors: {
      primary: props.theme.colors.primary,
      accent: props.theme.colors.accent,
      backdrop: rgba(0, 0, 0, 0.9),
      background: props.theme.colors.background,
      surface: props.theme.colors.background,
      text: props.theme.colors.text,
      disabled: props.theme.colors.midGray,
      placeholder: props.theme.colors.gray
    },
    fonts:
      Platform.OS === 'ios'
        ? {
            medium: 'SFProDisplay-Medium',
            regular: 'SFProDisplay-Regular',
            light: 'SFProDisplay-Light',
            thin: 'SFProDisplay-Thin'
          }
        : {
            medium: 'Roboto',
            regular: 'Roboto',
            light: 'Roboto',
            thin: 'Roboto'
          }
  }
}))``

export default PaperProvider
