import * as Yup from 'yup'
import { IRegistrationFields } from './UserRegistrationModal'

export const submitSchema = Yup.object().shape<
  Record<keyof IRegistrationFields, Yup.AnySchema>
>({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  workEmail: Yup.string().required().email('Email is not valid'),
  companyName: Yup.string().required(),
  jobTitle: Yup.string().required()
})
