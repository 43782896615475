import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const VideoIcon = React.memo(
  ({ color, size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg width={size} height={size} viewBox={`0 0 128 128`} {...rest}>
        <Path
          d="M112,13H16C7.2,13,0,20.2,0,29v70c0,8.8,7.2,16,16,16h96c8.8,0,16-7.2,16-16V29C128,20.2,120.8,13,112,13z M46.2,92V36
        l43.6,28L46.2,92z"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default VideoIcon
