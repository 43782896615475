import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import AudioIcon from '../icon/AudioIcon'
import VideoIcon from '../icon/VideoIcon'
import DurationRow from './DurationRow'
import MediaDetailBgGradient from './MediaDetailBgGradient'

const OuterContainer = styled(View)`
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  background-color: transparent;
`

const DurationRowContainer = styled(View)`
  ${({ theme: { space } }) => `
        position: absolute;
        bottom: ${space[1]}px;
        left: ${space[2]}px;
        width: ${space[4] + space[5]}px;
        flex-direction: row;
        align-items: center;
        z-index: 101;
    `}
`
const BackgroundContainer = styled(View)`
  ${({ theme: { space } }) => `
        position: absolute;
        bottom: -${space[4] + space[2] + space[1]}px;
    `}
`

const StyledDurationRow = styled(DurationRow).attrs({ color: 'white' })``

const StyledAudioIcon = styled(AudioIcon).attrs(({ theme: { sizes } }) => ({
  size: sizes[3] + sizes[1],
  color: 'white'
}))`
  ${({ theme: { space } }) => `
    margin-right: ${space[2]}px;
  `}
`

const StyledBackgroundGradient = styled(MediaDetailBgGradient).attrs(
  ({ theme: { sizes } }) => ({
    height: sizes[6] + sizes[2] + sizes[1],
    width: sizes[6] + sizes[5]
  })
)``

const StyledVideoIcon = styled(VideoIcon).attrs(({ theme: { sizes } }) => ({
  size: sizes[3] + sizes[1],
  color: 'white'
}))`
  ${({ theme: { space } }) => `
    margin-right: ${space[2]}px;
`}
`

interface MetaDataOverlayProps {
  type: string
  duration: number
}

const MetaDataOverlay = ({ duration, type }: MetaDataOverlayProps) => {
  return (
    <OuterContainer>
      <BackgroundContainer>
        <StyledBackgroundGradient />
      </BackgroundContainer>
      <DurationRowContainer>
        {type === 'audio' ? <StyledAudioIcon /> : <StyledVideoIcon />}
        <StyledDurationRow duration={duration} />
      </DurationRowContainer>
    </OuterContainer>
  )
}

export default MetaDataOverlay
