import React, { useState, useEffect, useContext } from 'react'

import { useFormik } from 'formik'
import { ActivityIndicator } from 'react-native-paper'
import styled, { ThemeContext } from 'styled-components/native'
import {
  ViewStyle,
  KeyboardAvoidingView,
  TouchableOpacityProps,
  TextInputProps
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import useIsFullScreenWeb from '../../hooks/useIsFullScreenWeb'
import useRegistrationMutation from '../../hooks/useRegistrationMutation'
import {
  getSavedRegistrationFields,
  IRegistrationFieldsSaved
} from './SavedRegistrationFields'
import useTranslation from '../../hooks/useTranslation'

import { Modal } from '../ThemedPaper'
import RegisterInput from './RegisterInput'
import UserRegistrationAgreements from './UserRegistrationAgreements'
// Validators
import { submitSchema } from './UserRegisterSchema'
// Types
import { ContentTypeEnum } from '../../types'

const Title = styled.Text`
  font-family: 'Helvetica';
  ${({ theme: { fontSizes, space, fontWeights } }) => `   
    font-weight: ${fontWeights.bold}
    font-size: ${fontSizes[5] + 1}px;  
    line-height: ${space[3] + 5}px; 
  `}
`
const Card = styled(KeyboardAvoidingView)`
  ${({ theme: { sizes, colors, radii } }) => `   
    width: 100%;
    height: 100%;
    align-self: center;
    flex-direction: column;
    max-width: ${sizes[8]}px;
    border-radius: ${radii[4] - 1}px;
    background-color: ${colors.background};
    padding-bottom: ${sizes[4] - 2}px;
    padding-horizontal: ${sizes[3] + 4}px;
    padding-top: ${sizes[4] + 12}px;
  `}
`
const CardContainer = styled.View`
  flex-direction: column;
`

const InnerContainer = styled.View`
  ${({ theme: { colors, radii } }) => `   
    width: 100%;
    align-self: center;
    flex-direction: column;
    border-radius: ${radii[3] + 2}px;
    border-color: ${colors.borderColor};
    border-width: 1px;
    position: relative;
    z-index: 1;
    margin-top: -12px;
    padding-horizontal: 20px;
    padding-top: 28px;
  `}
`
const TitleContainer = styled(CardContainer)`
  ${({ theme: { sizes, colors } }) => `   
    flex-direction: row;
    padding-left: ${sizes[3] + 6}px;
    position: relative;
    z-index: 2;
    margin-left: ${sizes[1] * 7}px;
    background-color: ${colors.background};
    padding: ${sizes[0]}px;
    width: fit-content;
  `}
`
const FormContainer = styled(CardContainer)`
  ${({ theme: { space } }) => `   
  padding-horizontal: ${space[0]}px;
  `}
`
const FormRow = styled.View`
  ${({ isFullScreenWeb, flexDirection }) => `   
    flex-flow: ${isFullScreenWeb ? 'column' : 'column no-wrap'};
    flex-direction: ${flexDirection || 'row'};
    justify-content: space-between;
  `}
`
const ColumnInput = styled.View`
  ${({ isFullScreenWeb }) => `
    flex-direction: column;
    margin-bottom: ${isFullScreenWeb ? 30 : 18}px;
    flex: ${isFullScreenWeb ? 'none' : 'auto'};
  `}
`
const ErrorsColumn = styled(FormRow)`
  flex-direction: column;
`
const ErrorText = styled.Text`
  ${({ theme: { space, fontSizes, fontWeights, colors } }) => ` 
    color: ${colors.inputTextError};
    padding-left: ${space[2]}px;
    font-size: ${fontSizes[3] + 2}px;
    font-weight: ${fontWeights.medium};
  `}
`
const ButtonFormRow = styled(FormRow)`
  margin-top: auto;
  justify-content: flex-end;
`
const Submit = styled.TouchableOpacity`
  ${({ isFullScreenWeb, theme: { radii, space, colors }, bgColor }) => `   
    flex-direction: row;
    align-items: center;
    border-radius: ${radii[5] - 2}px;
    padding-horizontal: ${space[4] - 2}px;
    padding-vertical: ${3 * space[1]}px;
    background-color: ${bgColor || colors.enabled};
    margin-top: ${isFullScreenWeb ? space[2] * 3 : space[3]}px;
    margin-bottom: ${space[4] - 2}px;;
    margin-left: ${space[2]}px;;
  `}
`
const SubmitText = styled.Text`
  font-family: 'Helvetica';
  ${({ theme: { fontSizes, space, fontWeights, colors } }) => `   
    font-weight: ${fontWeights.regular}
    font-size: ${fontSizes[3] + 2}px;  
    line-height: ${space[3]}px;
    color: ${colors.buttonText} 
  `}
`
const InputIdentifierContainer = styled(CardContainer)`
  ${({ theme: { sizes } }) => `   
    flex-direction: row;
    position: relative;
    padding: ${sizes[0]}px;
    width: fit-content;
  `}
`
const InputIdentifier = styled.Text`
  font-family: 'Helvetica';
  ${({ theme: { fontSizes, space, fontWeights } }) => `   
    font-weight: ${fontWeights.regular}
    font-size: ${fontSizes[3] + 2}px;  
    line-height: ${space[4]}px; 
  `}
`
const IdentifierSubject = styled.Text`
  ${({ theme: { fontSizes, space, fontWeights } }) => `   
    font-weight: ${fontWeights.regular}
    font-size: ${fontSizes[2]}px;  
    line-height: ${space[3] + 2}px;
    margin-left: ${space[2]}px;
    opacity: 0.4; 
    letter-spacing: 0.009em;
  `}
`
// Interfaces
export interface IRegistrationFields {
  firstName: string
  lastName: string
  companyName: string
  jobTitle: string
  workEmail: string
}
// Defaults
const DEFAULT_FORM_VALUES: IRegistrationFields = {
  firstName: '',
  lastName: '',
  workEmail: '',
  companyName: '',
  jobTitle: ''
}

interface UserRegistrationModalProps {
  contentId: string | null
  contentType: ContentTypeEnum | null
  isVisible: boolean
  isFetchLoading: boolean
  onCompleteRegister: (register: any) => void
}
const UserRegistrationModal: React.FC<UserRegistrationModalProps> = ({
  contentId,
  contentType,
  isVisible,
  isFetchLoading,
  onCompleteRegister
}) => {
  const { t } = useTranslation()
  const insets = useSafeAreaInsets()
  const { colors }: any = useContext(ThemeContext as any)
  const isFullScreenWeb = useIsFullScreenWeb()

  // States
  const [errors, setErrors] = useState<string[]>([])

  const contentContainerStyle: ViewStyle = {
    width: '100%',
    alignSelf: 'center',
    backgroundColor: 'transparent'
  }

  // Mutation
  const { registerForm, loading: loadingMutation } = useRegistrationMutation({
    onCompleted: ({ sharedContentRegistration }) => {
      onCompleteRegister(sharedContentRegistration)
    },
    onError: reason => {
      let msgError = t('error:registration:unknown')
      if (reason?.message?.length) {
        msgError =
          reason.message === 'Not Authorized'
            ? t('error:registration:noAuth')
            : reason.message
      }
      setErrors([msgError])
    }
  })

  // Events
  const onSubmit = async () => {
    setErrors([])
    try {
      const { values } = formik
      await submitSchema.validate(values, { abortEarly: false })
      const variables = {
        ...values,
        workEmail: values.workEmail.toLowerCase().trim(),
        contentType,
        contentId
      }
      registerForm({ variables })
    } catch (error: any) {
      let errors
      if (error?.errors?.length) {
        errors = [...error.errors]
      }
      setErrors(errors)
    }
  }

  const onLoadDefaultForm = async () => {
    const registrationForm: IRegistrationFieldsSaved | null = await getSavedRegistrationFields()
    if (registrationForm) {
      formik.setValues({ ...registrationForm })
    }
  }

  // Formik
  const formik = useFormik({
    initialValues: { ...DEFAULT_FORM_VALUES },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: submitSchema,
    onSubmit
  })

  // Effects
  useEffect(() => {
    onLoadDefaultForm()
  }, [])

  useEffect(() => {
    setErrors(Object.values(formik.errors))
  }, [formik.errors])

  // Props
  const isLoading: boolean = isFetchLoading || loadingMutation

  const submitEnable =
    !isLoading &&
    contentType &&
    contentId &&
    Object.values(formik.values).every(value => value?.length > 0)

  const submitProps: TouchableOpacityProps = {
    disabled: !submitEnable,
    onPress: () => formik.handleSubmit()
  }

  const commonInputProps = (
    key: keyof IRegistrationFields
  ): TextInputProps => ({
    value: formik.values?.[key],
    editable: !loadingMutation,
    onChangeText: (text: string) => {
      formik.setFieldValue(key, text)
    }
  })

  return (
    <Modal
      dismissable={false}
      visible={isVisible}
      contentContainerStyle={contentContainerStyle}
    >
      <Card
        behavior="padding"
        style={{ paddingBottom: Math.max(insets.bottom, 16) }}
      >
        <TitleContainer>
          <Title>{t('userRegistration:modal:title')}</Title>
        </TitleContainer>
        <InnerContainer>
          <FormContainer>
            {errors?.length > 0 ? (
              <ErrorsColumn>
                {errors.map((err: string, i: number) => (
                  <ErrorText key={`error-text-${err}-${i}`}>{err}</ErrorText>
                ))}
              </ErrorsColumn>
            ) : null}
            <FormRow>
              <ColumnInput isFullScreenWeb={isFullScreenWeb}>
                <InputIdentifierContainer>
                  <InputIdentifier>
                    {t('userRegistration:inputIdentifier:firstName')}
                  </InputIdentifier>
                </InputIdentifierContainer>
                <RegisterInput
                  textInputProps={{ ...commonInputProps('firstName') }}
                />
              </ColumnInput>
              <ColumnInput isFullScreenWeb={isFullScreenWeb}>
                <InputIdentifierContainer>
                  <InputIdentifier>
                    {t('userRegistration:inputIdentifier:lastName')}
                  </InputIdentifier>
                </InputIdentifierContainer>
                <RegisterInput
                  textInputProps={{ ...commonInputProps('lastName') }}
                />
              </ColumnInput>
            </FormRow>
            <FormRow>
              <ColumnInput isFullScreenWeb={isFullScreenWeb}>
                <InputIdentifierContainer>
                  <InputIdentifier>
                    {t('userRegistration:inputIdentifier:company')}
                  </InputIdentifier>
                </InputIdentifierContainer>
                <RegisterInput
                  textInputProps={{ ...commonInputProps('companyName') }}
                />
              </ColumnInput>
              <ColumnInput isFullScreenWeb={isFullScreenWeb}>
                <InputIdentifierContainer>
                  <InputIdentifier>
                    {t('userRegistration:inputIdentifier:jobTitle')}
                  </InputIdentifier>
                </InputIdentifierContainer>
                <RegisterInput
                  textInputProps={{ ...commonInputProps('jobTitle') }}
                />
              </ColumnInput>
            </FormRow>
            <FormRow flexDirection={'column'}>
              <ColumnInput>
                <InputIdentifierContainer>
                  <InputIdentifier>
                    {t('userRegistration:inputIdentifier:email')}
                    <IdentifierSubject>
                      {t('userRegistration:inputIdentifier:emailSubject')}
                    </IdentifierSubject>
                  </InputIdentifier>
                </InputIdentifierContainer>
                <RegisterInput
                  textInputProps={{ ...commonInputProps('workEmail') }}
                />
              </ColumnInput>
            </FormRow>
            <UserRegistrationAgreements />
            <ButtonFormRow>
              {isLoading && <ActivityIndicator />}
              <Submit
                isFullScreenWeb={isFullScreenWeb}
                bgColor={submitEnable ? colors.enabled : colors.lightBlue}
                {...submitProps}
              >
                <SubmitText>
                  {t('userRegistration:modal:submitButton')}
                </SubmitText>
              </Submit>
            </ButtonFormRow>
          </FormContainer>
        </InnerContainer>
      </Card>
    </Modal>
  )
}

export default UserRegistrationModal
