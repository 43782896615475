import React from 'react'

import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { RecoilRoot } from 'recoil'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider as ElementsProvider } from 'react-native-elements'
import { ApolloProvider } from '@apollo/client'
import PaperProvider from './PaperProvider'
import i18n from '../../i18n'
import elementsTheme from '../../themes/elements/theme'
import ThemeProvider from './ThemeProvider'
import API from '../../API'
import GlobalStyles from './GlobalStyles'
import UserRegistrationProvider from './UserRegistrationProvider'

const Providers = ({ children }: any) => {
  return (
    <RecoilRoot>
      <GlobalStyles />
      <ThemeProvider>
        <ElementsProvider theme={elementsTheme}>
          <I18nextProvider i18n={i18n}>
            <SafeAreaProvider>
              <ApolloProvider client={API}>
                <PaperProvider>
                  <UserRegistrationProvider>
                    {children}
                  </UserRegistrationProvider>
                </PaperProvider>
              </ApolloProvider>
              <StatusBar />
            </SafeAreaProvider>
          </I18nextProvider>
        </ElementsProvider>
      </ThemeProvider>
    </RecoilRoot>
  )
}

export default Providers
