import Constants from 'expo-constants'
import { Platform } from 'react-native'

const getApiUrl = protocol => {
  const { API_ENDPOINT, BUILD_ENV } = Constants.manifest.extra
  console.log(`api: `, API_ENDPOINT, BUILD_ENV, protocol)

  if (
    BUILD_ENV === 'production' ||
    BUILD_ENV === 'staging' ||
    BUILD_ENV === 'devlive'
  ) {
    // wrast: to avoid confusion with node_env=development, some of the
    //  references to the new dev environment were called "devlive",
    //  and some were named "development" to remain consistent with some
    //  naming conventions.
    // It may be worth reworking everything to be "devlive" at some point.
    const endpoints = {
      devlive: `${protocol}s://devlive-api.world50.com`,
      staging: `${protocol}s://staging-api.world50.com`,
      production: `${protocol}s://api.world50.com`
    }
    const url = endpoints[BUILD_ENV]
    if (url === undefined)
      throw new Error(`Unrecognized Environment: ${BUILD_ENV}`)
    return url
  } else {
    // wsrast: these new developer-only settings are needed to allow
    // testing on tethered handsets. Android in particular requires
    // you to use your local network IP address of your development
    // machine in order to address the API.
    // Note that you also need to update your debug/res/xml/network_security_config.xml
    // to include your machine's local IP.
    if (API_ENDPOINT === 'localhost')
      // if configured for localhost, use local addresses
      return Platform.select({
        ios: `${protocol}://localhost:7100`,
        android: `${protocol}://10.0.2.2:7100`,
        web: `${protocol}://localhost:7100`
      })
    else return `${protocol}://${API_ENDPOINT}:5000`
  }
}

export default getApiUrl
