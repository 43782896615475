import React from 'react'
import { Platform, Text } from 'react-native'
import { systemWeights, sanFranciscoSpacing } from 'react-native-typography'
import styled from 'styled-components/native'

const AllCapsText = ({ children, ...rest }: any) => {
  const capsChildren =
    typeof children === 'string' ? children.toUpperCase() : children
  return <Text {...rest}>{capsChildren}</Text>
}

const H1 = styled(Text)`
  ${systemWeights.light as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(96) } : {}};
  font-size: 96px;
  color: ${props => props.theme.colors.gray3};
  line-height: 96px;
`

const H2 = styled(Text)`
  ${systemWeights.light as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(60) } : {}};
  font-size: 60px;
  color: ${props => props.theme.colors.gray3};
  line-height: 60px;
`

const H3 = styled(Text)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(48) } : {}};
  font-size: 48px;
  color: ${props => props.theme.colors.gray3};
  line-height: 50px;
`

const H4 = styled(Text)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(34) } : {}};
  font-size: 34px;
  color: ${props => props.theme.colors.gray3};
  line-height: 40px;
`

const Headline = styled(Text)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(24) } : {}};
  font-size: 24px;
  color: ${props => props.theme.colors.text};
  line-height: 32px;
`

const PageTitle = styled(Text)`
  font-weight: 600;
  font-family: Inter;
  font-size: 30px;
  color: ${props => props.theme.colors.primary};
  line-height: 32px;
`

const Title = styled(Text)`
  ${systemWeights.semibold as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(20) } : {}};
  ${({ theme: { colors, fontSizes, space } }) => `
    font-family: Inter-Semibold;
    color: ${colors.buttonText};
    font-size: ${fontSizes[8] - 2}px;
    line-height: ${space[4] + 10}px;
  `}
`

const Subtitle1 = styled(Text)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(16) } : {}};
  ${({ theme: { colors, fontSizes, sizes } }) => `
    font-size: ${fontSizes[4] - 2}px;
    color: ${colors.buttonText};
    line-height: ${sizes[3]}px;
    font-family: Inter;
  `}
`

const Subtitle2 = styled(Text)`
  ${systemWeights.semibold as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(14) } : {}};
  font-size: ${props => props.theme.fontSizes[3] + 2}px;
  color: ${props => props.theme.colors.text};
  line-height: 22px;
`

const Body1 = styled(Text)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(16) } : {}};
  font-size: ${props => props.theme.fontSizes[4]}px;
  color: ${props => props.theme.colors.text};
  line-height: 20px;
  font-family: Inter;
`

const Body2 = styled(Text)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(14) } : {}};
  font-size: ${props => props.theme.fontSizes[3] + 2}px;
  color: ${props => props.theme.colors.text};
  line-height: 20px;
  font-family: Inter;
`

const Label = styled(AllCapsText)`
  ${systemWeights.semibold as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(14) } : {}};
  font-size: ${props => props.theme.fontSizes[3] + 2}px;
  color: ${props => props.theme.colors.text};
  /* line-height: 36px; */
`

const Caption = styled(Text)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(12) } : {}};
  font-size: ${props => props.theme.fontSizes[3] + 3}px;
  color: ${props => props.theme.colors.gray3};
  line-height: 20px;
`

const Overline = styled(AllCapsText)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(10) } : {}};
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.text};
  line-height: 32px;
`

const Subtext = styled(Text)`
  ${systemWeights.regular as any};
  ${Platform.OS === 'ios' ? { letterSpacing: sanFranciscoSpacing(10) } : {}};
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.text};
  line-height: 24px;
`

export {
  H1,
  H2,
  H3,
  H4,
  Headline,
  PageTitle,
  Title,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  Label,
  Caption,
  Overline,
  Subtext
}
