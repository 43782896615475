import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const NewDownloadIcon = React.memo(
  ({ color = 'white', size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg width={size} height={size} viewBox="0 0 24 24" {...rest}>
        <Path
          d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default NewDownloadIcon
