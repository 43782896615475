import React from 'react'
import { Text, Platform } from 'react-native'
import styled from 'styled-components/native'
import { ContentTopic } from './ContentTopic'

export const pillHeight = (theme, textSize: number): number => {
  return Platform.OS === 'android'
    ? textSize + theme.space[2] + 2
    : textSize + theme.space[2]
}

const TopicPillLabel = styled(Text).attrs({
  numberOfLines: 1
})`
  ${({ theme }) => `
  color: black;
  font-family: Inter;
  font-size: ${theme.fontSizes[3]}px;
  margin-top: -${theme.space[1] / 4}px;
  margin-left: ${pillHeight(theme, theme.fontSizes[2] + 1) / 2}px;
  margin-right: ${pillHeight(theme, theme.fontSizes[2] + 1) / 2}px;
  font-weight: ${theme.fontWeights.bold};
`}
`

export const TopicText = styled(TopicPillLabel).attrs({
  numberOfLines: 1
})`
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  ${({ theme: { fontWeights, colors } }) => `
    color: ${colors.secondary};
    font-weight: ${fontWeights.normal};
  `}
`
export interface TopicPillProps {
  topic: ContentTopic
  islastTopic?: boolean
}

export const TextTopicPill = ({ topic, islastTopic }: TopicPillProps) => (
  <TopicText>{`${topic.name}${!islastTopic ? ', ' : ''}`}</TopicText>
)
