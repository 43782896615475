import React, { createContext, FC, ReactNode, useCallback, useRef } from 'react'
import { NativeScrollEvent, ViewStyle } from 'react-native'
import styled from 'styled-components/native'

type FullScreenWebLayoutProps = {
  rightColumn?: ReactNode
  contentViewStyle?: ViewStyle
}

export const CONTAINER_WIDTH = 1127

export type ScrollListener = (evt: NativeScrollEvent) => void

/**
 * Consumed by a FlatList to implement infinite scrolling.
 * Right now we only support one listener at a time, but if
 * the need ever arises we can implement something more complicated
 */
export const ScrollParentListenerContext = createContext<
  (listener: ScrollListener | null) => void
>(() => {})

/**
 * Container for large screen format with multiple columns
 */
const FullScreenWebLayout: FC<FullScreenWebLayoutProps> = ({
  children,
  rightColumn,
  contentViewStyle
}) => {
  const listenerRef = useRef<ScrollListener | null>(null)

  const setListener = useCallback(
    (listener: ScrollListener | null) => (listenerRef.current = listener),
    []
  )

  return (
    <ScrollParentListenerContext.Provider value={setListener}>
      <StyledScrollView
        contentContainerStyle={[
          {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            position: 'relative',
            paddingTop: 35
          }
        ]}
        onScroll={({ nativeEvent }: { nativeEvent: NativeScrollEvent }) => {
          if (listenerRef.current) listenerRef.current(nativeEvent)
        }}
        // 32 ms arbitrarily chosen as a middle ground between performance
        // and responsiveness. Can be tweaked later if we run into problems
        scrollEventThrottle={32}
      >
        <ContentView style={contentViewStyle}>{children}</ContentView>
        {rightColumn ? <StickyView>{rightColumn}</StickyView> : null}
      </StyledScrollView>
    </ScrollParentListenerContext.Provider>
  )
}

const StyledScrollView = styled.ScrollView`
  display: block;
  background-color: ${props => props.theme.colors.white};
`

const StickyView = styled.View`
  ${({ theme: { space } }) => `
    position: sticky;
    top: ${space[3]}px;
    margin-right: ${space[3]}px;
    margin-left:  ${space[3]}px;
    height: calc(100vh - 71px);
    flex-basis: ${space[6] + space[5] + space[4] + space[3]}px;
    display: block;
    overflow-y: auto;
  `}
`

const ContentView = styled.View`
  width: ${CONTAINER_WIDTH}px;
  display: block;
`

export default FullScreenWebLayout
