import styled from 'styled-components/native'
import {
  flexbox,
  layout,
  color,
  space,
  typography,
  border,
  position,
  opacity,
  shadow,
  FlexboxProps,
  LayoutProps,
  ColorProps,
  SpaceProps,
  TypographyProps,
  BorderProps,
  PositionProps,
  OpacityProps,
  ShadowProps
} from 'styled-system'

interface BoxProps
  extends SpaceProps,
    LayoutProps,
    ColorProps,
    TypographyProps,
    BorderProps,
    PositionProps,
    OpacityProps,
    ShadowProps {}

export const Box = styled.View<BoxProps>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${border}
  ${position}
  ${opacity}
  ${shadow}
`

interface FlexProps
  extends SpaceProps,
    LayoutProps,
    ColorProps,
    FlexboxProps,
    TypographyProps,
    BorderProps,
    PositionProps,
    OpacityProps,
    ShadowProps {}

export const Flex = styled.View<FlexProps>`
  ${space}
  ${layout}
  ${color}
  ${flexbox}
  ${typography}
  ${border}
  ${position}
  ${opacity}
  ${shadow}
`
