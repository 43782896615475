import React from 'react'
import { View } from 'react-native'
import { Svg, Circle, Path } from 'react-native-svg'

interface IconProps {
  iconColor?: string
  iconOpacity?: string
  strokeRadius?: string
  strokeColor?: string
  strokeOpacity?: string
  strokeWidth?: string
  backgroundColor?: string
  backgroundOpacity?: string
  size?: number
  style?: object | object[]
}

const PlayButtonIcon = React.memo(
  ({
    iconColor = '#F5F5F5',
    iconOpacity = '0.8',
    strokeRadius = '61',
    strokeColor = '#F5F5F5',
    strokeOpacity = '0.6',
    strokeWidth = '6',
    backgroundColor = '#5D6466',
    backgroundOpacity = '0.5',
    size = 24,
    style,
    ...rest
  }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg width={size} height={size} viewBox={`0 0 128 128`} {...rest}>
        <Circle
          cx="64"
          cy="64"
          r="64"
          fill={backgroundColor}
          opacity={backgroundOpacity}
        />
        <Circle
          cx="64"
          cy="64"
          r={strokeRadius}
          fill="none"
          stroke={strokeColor}
          opacity={strokeOpacity}
          strokeWidth={strokeWidth}
        />
        <Path
          d="M50,41v46l36-23L50,41z"
          fill={iconColor}
          opacity={iconOpacity}
        />
      </Svg>
    </View>
  )
)

export default PlayButtonIcon
