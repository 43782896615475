import React, { useEffect, useContext } from 'react'
import {
  Portal,
  Modal as PaperModal,
  ModalProps as PaperModalProps
} from 'react-native-paper'
import { ThemeContext } from 'styled-components/native'
import { Keyboard, ViewStyle } from 'react-native'

interface ModalProps extends PaperModalProps {
  contentContainerStyle?: ViewStyle
}

const Modal = ({
  dismissable,
  onDismiss,
  visible,
  children,
  contentContainerStyle: inContentContainerStyle = {},
  theme: paperTheme
}: ModalProps) => {
  const theme: any = useContext(ThemeContext as any)

  const contentContainerStyle: ViewStyle = {
    alignSelf: 'center',
    backgroundColor: '#fff',
    width: theme.sizes[5] + theme.sizes[7],
    paddingLeft: theme.sizes[3],
    paddingRight: theme.sizes[3],
    ...inContentContainerStyle
  }

  useEffect(() => {
    if (visible) {
      Keyboard.dismiss()
    }
  }, [visible])

  return (
    <Portal>
      <PaperModal
        visible={visible}
        dismissable={dismissable}
        onDismiss={onDismiss}
        contentContainerStyle={contentContainerStyle}
        theme={paperTheme}
      >
        {children}
      </PaperModal>
    </Portal>
  )
}

export default Modal
