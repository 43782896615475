import React, { Fragment, useMemo, useContext, useEffect } from 'react'
import { ScrollView } from 'react-native'
import styled from 'styled-components/native'
import { RouteProp, useRoute } from '@react-navigation/native'

// Hooks
import useTranslation from '../../hooks/useTranslation'
import useMediaItemQuery from '../../hooks/useMediaItemQuery'
import useIsFullScreenWeb from '../../hooks/useIsFullScreenWeb'

//  Container/Components
import {
  BodyCard,
  BodyColumn,
  CardsContainer,
  DescriptionContainer,
  gapStyle,
  HeaderContainer,
  InfoCard,
  ItemHeader,
  RightColumn,
  SubTitle,
  Title,
  ConfidentialLabel
} from '../components'
import * as Text from '../../components/core/text'
import HostMedia from '../../components/av/HostMedia'
import DraftJSRenderer from '../../components/DraftJSRenderer'
import TopicFooter from '../../components/topics/EntityTopicFooter'
import PersonTagList from '../../components/personTag/PersonTagList'
import FullScreenWebLayout from '../../components/layout/FullScreenWebLayout'
import SharedContentTemplate from '../../components/layout/SharedContentTemplate'
import { ScreenContainerWithItemHeader } from '../../components/layout/ScreenContainer'
import Copyright from '../../components/common/Copyright'

// Types
import {
  HostMediaData,
  MediaVersion,
  VersionMap
} from '../../components/av/types'
import { ContentTypeEnum, RootStackParamList } from '../../types'
// Context
import { RegistrationStateContext } from '../../AppRoot/providers/UserRegistrationProvider'

// styled-components
export const TitleHeader = styled(ItemHeader)`
  ${({ isFullScreenWeb, theme: { space } }) => `
    margin-bottom: ${!isFullScreenWeb ? space[1] : 0}px;
    padding-horizontal: ${!isFullScreenWeb ? space[3] : 0}px;
  `}
`

const MediaItemScreen = () => {
  // Hooks
  const { onContentAdded, registrationLoading } = useContext(
    RegistrationStateContext
  )
  const route = useRoute<MediaItemRouteProp>()
  const id = route.params?.id
  const { media, loading } = useMediaItemQuery(id)
  const { t } = useTranslation()
  const isFullScreenWeb = useIsFullScreenWeb()
  const hostMedias = getHostMediasByVersion(media)
  const isDataLoading = loading || !media?.id
  const mediaMetadata = {
    ...media,
    hostMedias
  }

  // Effects
  useEffect(() => {
    if (media?.id && !isDataLoading) {
      onContentAdded({
        contentId: media.id,
        contentTitle: media.title,
        contentTopics: media.topics,
        contentType: ContentTypeEnum.media
      })
    }
  }, [media, isDataLoading])

  // Render
  const description = useMemo(() => {
    if (isDataLoading) return null
    if (media.description) {
      try {
        return JSON.parse(media.description)
      } catch {
        console.log(
          'Could not parse media description. Rendering as plain text.'
        )
      }
    }
    return media.description
  }, [media, isDataLoading])

  if (isDataLoading || registrationLoading) {
    return null
  }

  const Wrapper = isFullScreenWeb ? FullScreenWebLayout : Fragment

  return (
    <ScreenContainerWithItemHeader
      webTitle={media.title}
      screenCategory="media"
    >
      <SharedContentTemplate />
      <ScrollView>
        <Wrapper>
          {/* Header */}
          <HeaderContainer
            flexDirection={isFullScreenWeb ? 'row-reverse' : 'column'}
          >
            <ItemHeader isFullScreenWeb={isFullScreenWeb}>
              <HostMedia media={mediaMetadata} />
            </ItemHeader>
            <TitleHeader
              isFullScreenWeb={isFullScreenWeb}
              style={isFullScreenWeb ? { paddingRight: gapStyle.gap } : {}}
            >
              <Title isFullScreenWeb={isFullScreenWeb}>{media.title}</Title>
              {media?.subtitle && (
                <SubTitle isFullScreenWeb={isFullScreenWeb}>
                  {media.subtitle}
                </SubTitle>
              )}
              {media?.personTags?.length ? (
                <PersonTagList personTags={media?.personTags} />
              ) : null}
              <TopicFooter topics={media.topics} wrapPills />
              <ConfidentialLabel>{t('common:confidential')}</ConfidentialLabel>
            </TitleHeader>
          </HeaderContainer>
          {/* Cards */}
          <CardsContainer isFullScreenWeb={isFullScreenWeb}>
            {/* Left Column */}
            <BodyColumn isFullScreenWeb={isFullScreenWeb}>
              <BodyCard>
                <DescriptionContainer isFullScreenWeb={isFullScreenWeb}>
                  {typeof description === 'string' ? (
                    <Text.Body2>{description}</Text.Body2>
                  ) : (
                    <DraftJSRenderer value={description} />
                  )}
                </DescriptionContainer>
              </BodyCard>
            </BodyColumn>
            {/* Right column */}
            <RightColumn isFullScreenWeb={isFullScreenWeb}>
              <InfoCard
                title={t('common:promo:title')}
                bodyText={t('common:promo:description')}
              />
            </RightColumn>
            <Copyright />
          </CardsContainer>
        </Wrapper>
      </ScrollView>
    </ScreenContainerWithItemHeader>
  )
}

type MediaItemRouteProp = RouteProp<RootStackParamList, 'MediaItem'>
const getHostMediasByVersion = media => {
  let hostMedias = {}
  if (media?.highlightsHostMedia) {
    hostMedias[MediaVersion.Highlights] = media?.highlightsHostMedia
  }
  if (media?.hostMedia) {
    hostMedias[MediaVersion.Main] = media?.hostMedia
  }
  return hostMedias as VersionMap<HostMediaData>
}

export default MediaItemScreen
