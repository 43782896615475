import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

type DigitUnitMeasure = 'bytes' | 'kb' | 'mb' | 'gb' | 'tb'

const orderedSizes: DigitUnitMeasure[] = ['bytes', 'kb', 'mb', 'gb', 'tb']

const useFileStats = () => {
  const { t } = useTranslation()

  const bytesToSize = useCallback(
    (bytes: number = 0) => {
      if (bytes === 0) return `${bytes} ${t('unitMeasure:digit:byte')}`

      const measureIndex = Math.floor(Math.log(bytes) / Math.log(1024))
      const unitMeasure = orderedSizes[measureIndex]

      return `${Math.round(bytes / Math.pow(1024, measureIndex))} ${t(
        `unitMeasure:digit:${unitMeasure}`
      )}`
    },
    [t]
  )

  const blobToDataURL = useCallback(
    (blob: Blob, callback: (e?: string | ArrayBuffer | null) => any) => {
      var fileReader = new FileReader()
      fileReader.onload = e => {
        // TODO: Dig into this one. Consider refactoring. -- Mo/Jo, 2022-08-31
        // eslint-disable-next-line
        callback(e?.target?.result)
      }
      fileReader.readAsDataURL(blob)
    },
    []
  )

  const getFileExtension = (fileName: string = '') => {
    return fileName.split('.').pop()
  }

  return { bytesToSize, blobToDataURL, getFileExtension }
}

export default useFileStats
