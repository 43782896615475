import React, { useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { ThemeContext } from 'styled-components/native'
import AppNavigator from './AppNavigator'

const Stack = createStackNavigator()

const RootNavigator = () => {
  const theme = useContext(ThemeContext) as any
  const screenOptions = {
    headerMode: 'none',
    cardStyle: {
      backgroundColor: theme.colors.appBackground,
      flex: 1
    }
  }

  return (
    <Stack.Navigator screenOptions={screenOptions}>
      <Stack.Screen
        name="App"
        component={AppNavigator}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  )
}

export default RootNavigator
