import AsyncStorage from '@react-native-async-storage/async-storage'
import { IRegistrationFields } from './UserRegistrationModal'

export interface IRegistrationFieldsSaved extends IRegistrationFields {
  id?: string
}

export const registrationFieldsAsyncKey: string = 'registrationFields'

export const getSavedRegistrationFields = async (): Promise<IRegistrationFieldsSaved | null> => {
  let savedFields: any = await AsyncStorage.getItem(registrationFieldsAsyncKey)
  if (savedFields) {
    savedFields = JSON.parse(savedFields)
  }
  return {
    firstName: savedFields?.firstName || '',
    lastName: savedFields?.lastName || '',
    companyName: savedFields?.companyName || '',
    jobTitle: savedFields?.jobTitle || '',
    workEmail: savedFields?.workEmail || ''
  }
}
