import { Cloudinary } from 'cloudinary-core'
import Constants from 'expo-constants'

const { CLOUDINARY_NAME } = Constants.manifest.extra

const cl = new Cloudinary({
  cloud_name: CLOUDINARY_NAME,
  secure: true
})

const idToCloudinaryUrl = (publicId, options = {}) => {
  return cl.url(publicId, options)
}

export default idToCloudinaryUrl
