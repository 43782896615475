import * as Linking from 'expo-linking'

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      App: {
        screens: {
          MediaItem: '/media/:id',
          DocumentItem: '/document/:id',
          ComingSoon: '/'
        }
      }
    }
  }
}
