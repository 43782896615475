import { View } from 'react-native'
import styled from 'styled-components/native'
import CloudinaryImage from '../CloudinaryImage'

export const VideoSizeValues = {
  small: {
    height: 52,
    width: 92
  },
  normal: {
    height: 232,
    width: `100%`
  }
}

export const VideoCssProps = {
  height: VideoSizeValues.normal.height,
  left: 0,
  top: 88,
  width: VideoSizeValues.normal.width
}

export const VideoMarkerSizes = {
  small: `height: ${VideoSizeValues.small.height}px; width: ${VideoSizeValues.small.width}px;`,
  normal: `height: ${VideoSizeValues.normal.height}px; width: ${VideoSizeValues.normal.width};`
}

const HeroVideoBkgColor = props => `${props.theme.colors.gray4}`

export const Hero = styled(CloudinaryImage)`
  height: 100%;
  width: 100%;
  left: 0px;
  background-color: ${props => HeroVideoBkgColor(props)};
`

export const HeroPlaceholder = styled(View)`
  height: 100%;
  width: 100%;
  left: 0px;
  background-color: ${props => HeroVideoBkgColor(props)};
`

const VideoBaseStyled = styled(View)`
  height: 100%;
  width: 100%;
  left: 0px;
`

export default VideoBaseStyled
