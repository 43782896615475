import React from 'react'
import * as Text from '../core/text'
import useTranslation from '../../hooks/useTranslation'
import styled from 'styled-components/native'

const DurationText = styled(Text.Caption)`
  ${({ color }) => `
    ${color ? `color: ${color}` : ``}
  `}
`

const DurationRow = ({ duration, color }: props) => {
  const { t } = useTranslation()
  if (!duration) {
    return null
  }
  const mins = Math.floor(duration / 60) || 1
  const str = `${mins} ${t('dateTime:minutesShorthand')} `
  return <DurationText color={color}>{str}</DurationText>
}

interface props {
  duration: number
  color?: any
}

export default DurationRow
