import React from 'react'
import { Dimensions } from 'react-native'
import styled from 'styled-components/native'
import { ActivityIndicator } from 'react-native-paper'
// Components
import * as Text from '../components/core/text'
import { Card } from '../components/common/Card'
import { CONTAINER_WIDTH } from '../components/layout/FullScreenWebLayout'

// Style constants
export const gapStyle = { gap: CONTAINER_WIDTH * 0.025 }

// Containers
export const HeaderContainer = styled.View`
  flex-direction: row;
  margin-bottom: ${gapStyle.gap}px;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
`

export const CardsContainer = styled.View`
  ${({ isFullScreenWeb, theme: { space } }) => `
    flex-flow: ${isFullScreenWeb ? 'row wrap' : 'column no-wrap'};
    padding-horizontal: ${isFullScreenWeb ? '0' : space[3]}px;
  `}
`

export const DescriptionContainer = styled.ScrollView`
  ${({ isFullScreenWeb, theme: { space } }) => `
    height: ${
      isFullScreenWeb ? `${Dimensions.get('screen').height / 2}px` : 'auto'
    };
    padding: ${space[4] + 3}px;
  `}
`

// Columns
export const BodyColumn = styled.View`
  width: 100%;
  flex-grow: 2;
  ${({ isFullScreenWeb }) => `
    max-width: ${isFullScreenWeb ? '75%' : '100%'};
    margin-bottom: ${!isFullScreenWeb ? gapStyle.gap : 0}px;
  `}
`

export const RightColumn = styled.View`
  flex-grow: 1;
  margin-left: auto;
  flex-direction: column;
  ${({ isFullScreenWeb }) =>
    `max-width: ${isFullScreenWeb ? '22.5%' : '100%'}`};
`

// Items
export const ItemHeader = styled.View`
  flex-direction: column;
  justify-content: center;
  ${({ isFullScreenWeb }) => (isFullScreenWeb ? 'flex: 1;' : 'width: 100%')}
`

export const BodyCard = styled(Card).attrs(({ containerStyle }) => ({
  containerStyle: {
    ...containerStyle,
    borderWidth: 0,
    overflow: 'hidden'
  }
}))``

export const InfoCard = styled(Card).attrs(({ theme: { space } }) => ({
  containerStyle: { padding: space[4] - 2, paddingTop: space[3] + 4 }
}))`
  margin-bottom: ${gapStyle.gap}px;
`

// Text
export const Title = styled(Text.Title)`
  text-transform: capitalize;
  ${({ isFullScreenWeb, theme: { space, fontSizes } }) => `
    font-size: ${isFullScreenWeb ? fontSizes[8] - 2 : fontSizes[7]}px;
    padding-right: ${isFullScreenWeb ? gapStyle.gap : 0}px;
    margin-bottom: ${gapStyle.gap - 10}px;
    line-height: ${isFullScreenWeb ? space[4] + 10 : space[4]}px;
  `}
`
export const SubTitle = styled(Text.Subtitle1)`
  text-transform: capitalize;
  ${({ isFullScreenWeb }) => `
    padding-right: ${isFullScreenWeb ? gapStyle.gap : 0}px;
    margin-bottom: ${gapStyle.gap - 10}px;
  `}
`
export const ConfidentialLabel = styled.Text`
  font-style: italic;
  ${({ theme: { colors, fontSizes } }) => `
    color: ${colors.buttonText};
    font-family: Inter;
    font-size: ${fontSizes[3]}px;
    margin-top: ${gapStyle.gap - 10}px;
  `}
`

// Loading
const LoadingContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const LoadingIndicator = () => (
  <LoadingContainer>
    <ActivityIndicator />
  </LoadingContainer>
)
