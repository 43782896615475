import React from 'react'
import styled from 'styled-components/native'
import { ViewProps, TextStyle } from 'react-native'
import { List, ItemProps } from 'react-native-paper'
// Components
import CloudinaryAvatar from '../CloudinaryAvatar'

const Container = styled.View`
  width: 100%;
`

const itemStyle: TextStyle = {
  paddingLeft: 0,
  paddingTop: 0
}

const Item = styled(List.Item).attrs(({ theme: fontSizes }) => ({
  titleStyle: {
    color: 'white',
    lineHeight: 16,
    fontFamily: 'Inter',
    fontSize: fontSizes[3] + 2
  }
}))``

const PersonRow = React.memo(
  ({
    id,
    name,
    imageId,
    imageSize = 20,
    listItemProps,
    expanded = null,
    ...rest
  }: PersonRowProps) => {
    return (
      <Container {...rest}>
        <Item
          id={id}
          title={name}
          expanded={expanded || open}
          style={itemStyle}
          left={props =>
            imageId ? (
              <CloudinaryAvatar
                {...props}
                size={imageSize}
                publicId={imageId}
              />
            ) : null
          }
          {...listItemProps}
        />
      </Container>
    )
  }
)

interface PersonRowProps extends ViewProps {
  id?: number | string
  name?: string
  icon?: JSX.Element
  imageId?: string
  imageSize?: number
  style?: any
  listItemProps?: ItemProps
  expanded?: boolean | null
}

export default PersonRow
