import { useState, useCallback } from 'react'

const useToggleState = (initialToggled = false) => {
  const [isOn, setState] = useState(initialToggled)

  const turnOn = useCallback(() => {
    setState(true)
  }, [])

  const turnOff = useCallback(() => {
    setState(false)
  }, [])

  const toggle = useCallback(() => {
    const newVal = !isOn
    setState(newVal)
    return newVal
  }, [isOn])

  return {
    turnOn,
    turnOff,
    toggle,
    isOn
  }
}

export default useToggleState
