import React from 'react'
import idToCloudinaryUrl from '../../utils/idToCloudinaryUrl'

interface MediaPlayerProps {
  source?: string
  poster?: string
  style?: any
  type: string
}

const MediaPlayer = ({ source, style, type, poster }: MediaPlayerProps) => (
  <video
    style={{ ...style, transition: 'all 0.3s ease-in-out' }}
    controls
    autoPlay
    src={source}
    controlsList="nodownload"
    poster={type === 'audio' ? idToCloudinaryUrl(poster) : ''}
  />
)

export default MediaPlayer
