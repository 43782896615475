import React, { useEffect, useRef, useState } from 'react'
import { LayoutChangeEvent, View, ViewStyle } from 'react-native'
import styled from 'styled-components/native'
// Hooks
import useTranslation from '../../hooks/useTranslation'
import useIsFullScreenWeb from '../../hooks/useIsFullScreenWeb'
// Components
import MediaMarker from './MediaMarker'
import VideoBase from './VideoBase'
// Types
import { MediaMetaData, MediaVersion, HostMediaI, HostMediaData } from './types'

const HostMediaContainer = styled.View`
  width: 100%;
`

const ViewPlaceholder = styled(VideoBase)`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const HeaderButtons = styled.View`
  width: 100%;
  flex-direction: row;
  border-bottom-width: 1px;
  margin-bottom: ${props => props.theme.space[2]}px;
  border-bottom-color: ${props => props.theme.colors.gray6};
`
const HeaderButton = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  margin-right: ${props => props.theme.space[3]}px;
  padding-vertical: ${props => props.theme.space[3]}px;
  padding-horizontal: ${props => props.theme.space[1]}px;
  ${({ theme: { sizes, colors }, selected }) =>
    ` border-bottom-width: ${sizes[selected ? 1 : 0]}px;
      border-bottom-color: ${selected ? colors.secondary : colors.gray6};`}
`
const HeaderText = styled.Text`
  font-family: Inter;
  text-transform: capitalize;
  font-size: ${props => props.theme.fontSizes[4]}px;
  ${({ theme: { colors, fontWeights }, selected }) =>
    `color: ${selected ? colors.secondary : colors.gray6};
    font-weight: ${selected ? fontWeights.bold : fontWeights.regular};`}
`

const padTo2Digits = num => num.toString().padStart(2, '0')

const toHoursAndMinutes = totalMinutes => {
  const minutes = totalMinutes % 60
  const hours = Math.floor(totalMinutes / 60)
  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
}

interface HostMediaProps {
  media?: MediaMetaData
  style?: ViewStyle
}

const HostMedia = ({ media }: HostMediaProps) => {
  // Ref, States
  const placeholderRef = useRef<View>(null)
  const { t } = useTranslation()
  const isFullScreenWeb = useIsFullScreenWeb()
  const image = media && media.image
  const [videoMarkers, setVideoMarkers] = useState<HostMediaI>({ layout: {} })
  const [currentHost, setCurrentHost] = React.useState<string>(
    Object.keys(media.hostMedias)[0]
  )

  const hosts = Object.keys(media.hostMedias)
    .filter(host => media.hostMedias[host])
    .map(host => ({
      key: host,
      title: t(`media:hostPlaceholders:${host}`, {
        type: media.hostMedias[host]?.type || 'Content',
        duration: toHoursAndMinutes(
          Math.round(media.hostMedias[host]?.duration)
        )
      })
    }))

  const currentHostMedia: HostMediaData = media.hostMedias[currentHost]

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    const width = nativeEvent?.layout?.width
    setVideoMarkers({
      layout: {
        ...videoMarkers.layout,
        ...computeVideoBounds(width, media, currentHost)
      }
    })
  }

  useEffect(() => {
    if (
      currentHostMedia?.width &&
      currentHostMedia?.height &&
      placeholderRef?.current
    ) {
      placeholderRef.current.measure((ox, oy, width) => {
        setVideoMarkers({
          layout: {
            ...computeVideoBounds(width, media, currentHost)
          }
        })
      })
    }
  }, [currentHost, currentHostMedia])

  const renderHeaderButtons = () => {
    if (hosts?.length > 1) {
      return (
        <HeaderButtons>
          {hosts.map(host => (
            <HeaderButton
              key={host.key}
              selected={currentHost === host.key}
              onPress={() => setCurrentHost(host.key)}
            >
              <HeaderText selected={currentHost === host.key}>
                {host.title}
              </HeaderText>
            </HeaderButton>
          ))}
        </HeaderButtons>
      )
    }
    return null
  }

  return (
    <HostMediaContainer>
      {isFullScreenWeb && renderHeaderButtons()}
      <ViewPlaceholder ref={placeholderRef} onLayout={onLayout}>
        <MediaMarker
          image={image}
          videoMarkers={videoMarkers}
          currentHostMedia={currentHostMedia}
        />
      </ViewPlaceholder>
      {!isFullScreenWeb && renderHeaderButtons()}
    </HostMediaContainer>
  )
}

const computeVideoBounds = (layoutWidth, media, version): ViewStyle => {
  if (layoutWidth && media && version) {
    let width = layoutWidth
    if (width > 640) {
      width = 640
    }
    const minRatio = 4 / 3
    const standardRatio = 16 / 9
    const maxRatio = 21 / 9
    let hostMedia =
      version === MediaVersion.Highlights
        ? media.highlightsHostMedia
        : media.hostMedia

    if (hostMedia.height === 0) {
      hostMedia =
        version === MediaVersion.Highlights || !media.highlightsHostMedia
          ? media.hostMedia
          : media.highlightsHostMedia
    }

    const mediaRatio = hostMedia.width / hostMedia.height || standardRatio
    if (mediaRatio < minRatio) {
      return {
        height: width / minRatio,
        width: '100%'
      }
    } else if (mediaRatio > maxRatio) {
      return {
        height: width / maxRatio,
        width: '100%'
      }
    }
    return {
      height: width / mediaRatio,
      width: '100%'
    }
  }
  return { width: '100%' }
}

export default HostMedia
