import Theme from '../../constants/Theme'

export default {
  colors: {
    primary: Theme.colors.primary,
    brightPrimary: Theme.colors.brightPrimary,
    accent: Theme.colors.accent,
    appBackground: Theme.colors.appBackground,
    background: Theme.colors.background,
    text: Theme.colors.text,
    midGray: Theme.colors.midGray,
    gray3: Theme.colors.gray3,
    gray4: Theme.colors.gray4,
    gray5: Theme.colors.gray5,
    buttonText: Theme.colors.buttonText,
    placeholderText: Theme.colors.placeholderText,
    border: Theme.colors.border,
    attention: Theme.colors.attention,
    enabled: Theme.colors.enabled,
    disabled: Theme.colors.disabled
  },
  space: Theme.space,
  sizes: Theme.sizes,
  containerRadius: Theme.containerRadius,
  fontSizes: Theme.fontSizes,
  fontWeights: {
    thin: Theme.fontWeights.thin,
    light: Theme.fontWeights.light,
    regular: Theme.fontWeights.regular,
    medium: Theme.fontWeights.medium,
    bold: Theme.fontWeights.bold,
    black: Theme.fontWeights.black
  }
}
