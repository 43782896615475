import React, { useContext } from 'react'
import { View } from 'react-native'
import { ThemeContext } from 'styled-components/native'
import { TextComponentType } from './index'

interface Props {
  key: string
  TextComponent: TextComponentType
  children: JSX.Element[]
}

const QuoteBlock = ({ key, TextComponent, children }: Props) => {
  const { colors, sizes, space } = useContext(ThemeContext as any)

  return (
    <View
      key={key}
      style={{
        borderLeftColor: colors.midGray,
        borderLeftWidth: sizes[1] / 2,
        marginLeft: space[4],
        marginTop: space[2],
        marginBottom: space[2],
        padding: space[1],
        paddingLeft: space[2]
      }}
    >
      <TextComponent style={{ flex: 1 }}>{children}</TextComponent>
    </View>
  )
}

export default QuoteBlock
