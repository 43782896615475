import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const query = gql`
  query getShareableDocument($id: String!) {
    getShareableDocument(id: $id) {
      id
      title
      subtitle
      type
      body
      publishDate
      shareable
      topics {
        id
        name
        slug
      }
      image {
        id
        cloudinaryId
      }
      file {
        id
        fileName
        cloudinaryId
      }
      personTags {
        person {
          id
          firstName
          lastName
          bio
          photo {
            id
            cloudinaryId
          }
        }
        source {
          __typename
          id
          title
          published
        }
      }
    }
  }
`

const useDocumentItemQuery = (id: string) => {
  const { data, loading } = useQuery(query, {
    variables: { id },
    skip: !id
  })

  return {
    document: data?.getShareableDocument,
    loading
  }
}

export default useDocumentItemQuery
