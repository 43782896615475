const getFileFromUrl = (url: string = '', fileName: string = '') => {
  const [, format] = fileName.split('.')

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': `application/${format}`
    }
  }).then(response => response.blob())
}

export default getFileFromUrl
