import React, { useMemo } from 'react'
import styled from 'styled-components/native'
import { RawDraftContentState } from 'draft-js'
import * as Text from '../core/text'
import useOpenUrl from '../../hooks/useOpenUrl'
import UnorderedListBlock from './UnorderedListBlock'
import OrderedListBlock from './OrderedListBlock'
import QuoteBlock from './QuoteBlock'
import SegmentText from './SegmentText'

// These are not consistent with the app, but they are more consistent with mi6 which is where this content originates
// The plan is to mock this out here for now, get more official styles from product/UX and move all this to shared components
const Heading = styled(Text.Subtitle1)`
  font-size: 20px;
  line-height: 48px;
`

const SubHeading = styled(Text.Subtitle2)`
  font-size: 18px;
  line-height: 36px;
`

const styles = {
  unstyled: {},
  'header-one': {
    fontSize: 18,
    fontWeight: '700'
  },
  'header-two': {
    fontSize: 16,
    fontWeight: '400'
  }
}

const applyRangeToSegment = (segment, range) => {
  let newSegment = { ...segment }
  if (range.style === 'BOLD') {
    newSegment.bold = true
  } else if (range.style === 'ITALIC') {
    newSegment.italic = true
  } else if (range.style === 'UNDERLINE') {
    newSegment.underline = true

    // Assume it's an entity because there are only 3 options
  } else {
    newSegment.entityKey = range.key
  }

  return newSegment
}

// This algorithm takes a block of text and chunks it into segments such
// that all the text in a segment shares the same styling and metadata.
const segmentBlockText = block => {
  const { text, inlineStyleRanges, entityRanges } = block

  let segments = [
    {
      // Empty text is rendered with zero height.
      // Spaces render with the proper height so we fake it here.
      text: text === '' ? ' ' : text,
      bold: false,
      italic: false,
      underline: false,
      entityKey: null
    }
  ]

  let ranges = [...inlineStyleRanges, ...entityRanges]

  // Loop over ranges. For each range, loop over segments and detect overlap.
  // If a range overlaps a segment then the segment is split unless
  // the range overlaps the ENTIRE segment.
  for (let ri = 0; ri < ranges.length; ri++) {
    const range = ranges[ri]
    let rStart = range.offset
    let rEnd = range.offset + range.length

    let segStart = 0
    for (let si = 0; si < segments.length; si++) {
      const segment = segments[si]
      let segmentLength = segment.text.length
      let segEnd = segStart + segmentLength

      // There are four cases we need to handle
      // 1. The range applies to the entire segment
      // 2. The range starts in the middle of the segment.
      // 3. The range ends in the middle of the segment.
      // 4. The range starts and ends in the middle of the segment.
      if (rStart <= segStart && rEnd >= segEnd) {
        segments[si] = applyRangeToSegment(segment, range)
      } else if (rStart > segStart && rStart < segEnd && rEnd >= segEnd) {
        const leftSeg = {
          ...segment,
          text: segment.text.slice(0, rStart - segStart)
        }
        const rightSeg = applyRangeToSegment(
          {
            ...segment,
            text: segment.text.slice(rStart - segStart)
          },
          range
        )
        segments.splice(si, 1, leftSeg, rightSeg)
        si++
      } else if (rStart <= segStart && rEnd > segStart && rEnd < segEnd) {
        const leftSeg = applyRangeToSegment(
          {
            ...segment,
            text: segment.text.slice(0, rEnd - segStart)
          },
          range
        )
        const rightSeg = {
          ...segment,
          text: segment.text.slice(rEnd - segStart)
        }
        segments.splice(si, 1, leftSeg, rightSeg)
        si++
      } else if (
        rStart > segStart &&
        rStart < segEnd &&
        rEnd > segStart &&
        rEnd < segEnd
      ) {
        const leftSeg = {
          ...segment,
          text: segment.text.slice(0, rStart - segStart)
        }
        const middleSeg = applyRangeToSegment(
          {
            ...segment,
            text: segment.text.slice(rStart - segStart, rEnd - segStart)
          },
          range
        )
        const rightSeg = {
          ...segment,
          text: segment.text.slice(rEnd - segStart)
        }
        segments.splice(si, 1, leftSeg, middleSeg, rightSeg)
        si += 2
      }

      segStart += segmentLength
    }
  }

  return segments
}

interface DraftJSRendererProps {
  value: RawDraftContentState
  communityId?: string
}

export type TextComponentType =
  | typeof Heading
  | typeof SubHeading
  | typeof Text.Body2

const componentMap = {
  heading: Heading,
  'sub-heading': SubHeading,
  unstyled: Text.Body2
}

const DraftJSRenderer = ({ value }: DraftJSRendererProps) => {
  const openUrl = useOpenUrl()
  const { blocks, entityMap } = value

  const renderedBlocks = useMemo(() => {
    let spacesInARow = 0
    let listItemIndex = 0
    return blocks.map(block => {
      const TextComponent: TextComponentType =
        componentMap[block.type] || Text.Body2
      if (block.text.trim() === '' && block.type === 'unstyled') {
        spacesInARow++
      } else {
        spacesInARow = 0
      }

      // Only one empty block is allowed between blocks.
      if (spacesInARow > 1) {
        return null
      }

      let segments = segmentBlockText(block)

      const renderSegmentText = (segment, index, onPress) => {
        return (
          <SegmentText
            key={`${block.key}-${index}`}
            onPress={onPress}
            style={{ ...styles[block.type] }}
            TextComponent={TextComponent}
            segment={segment}
          />
        )
      }

      const renderedSegments = segments.map((seg, i) => {
        if (seg.entityKey !== null) {
          const entity = entityMap[seg.entityKey as any]
          const url = entity.data.url

          return renderSegmentText(seg, i, () => openUrl(url, true))
        }
        return renderSegmentText(seg, i, undefined)
      })

      if (block.type !== 'ordered-list-item' && listItemIndex !== 0) {
        listItemIndex = 0
      }

      if (block.type === 'unordered-list-item') {
        return (
          <UnorderedListBlock
            key={block.key}
            TextComponent={TextComponent}
            children={renderedSegments}
          />
        )
      }

      if (block.type === 'ordered-list-item') {
        ++listItemIndex
        return (
          <OrderedListBlock
            key={block.key}
            TextComponent={TextComponent}
            children={renderedSegments}
            listItemIndex={listItemIndex}
          />
        )
      }

      if (block.type === 'blockquote') {
        return (
          <QuoteBlock
            key={block.key}
            TextComponent={TextComponent}
            children={renderedSegments}
          />
        )
      }
      return <TextComponent key={block.key}>{renderedSegments}</TextComponent>
    })
  }, [value])

  return <>{renderedBlocks}</>
}

export default DraftJSRenderer
