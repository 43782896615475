import React, { useContext } from 'react'
import { View } from 'react-native'
import { ThemeContext } from 'styled-components/native'
import { TextComponentType } from './index'

interface Props {
  key: string
  TextComponent: TextComponentType
  children: JSX.Element[]
  listItemIndex: number
}

const OrderedListBlock = ({
  key,
  TextComponent,
  children,
  listItemIndex
}: Props) => {
  const { space } = useContext(ThemeContext as any)

  return (
    <View key={key} style={{ flexDirection: 'row' }}>
      <TextComponent
        style={{
          marginLeft: space[1],
          marginRight: space[1]
        }}
      >
        {`${listItemIndex}.`}
      </TextComponent>
      <TextComponent style={{ flex: 1 }}>{children}</TextComponent>
    </View>
  )
}

export default OrderedListBlock
