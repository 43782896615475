import React from 'react'
import styled from 'styled-components/native'
// Components
import PersonRow from '../list/PersonRow'

const Container = styled.ScrollView`
  ${({ theme: { sizes } }) => `
    flex-grow: 0;
    height: auto;
    max-height: ${sizes[6] + 50}px;
    margin-bottom: ${sizes[2] + 2}px;
  `}
`

const PersonTagList: React.FC<PersonTagListProps> = ({ personTags }) => {
  // Renders
  if (!personTags?.length) return null
  return (
    <Container>
      {personTags.map(({ person }, index) => (
        <PersonRow
          key={`key_personRow_${index}_${person?.id || ''}`}
          id={person.id}
          imageId={person.photo?.cloudinaryId}
          name={`${person.firstName || ''} ${person.lastName || ''}`}
        />
      ))}
    </Container>
  )
}

interface PersonTagListProps {
  personTags: any[]
}

export default PersonTagList
