import { Platform, Dimensions } from 'react-native'
import { FULLSCREEN_WIDTH } from '../utils/constants'
import { useEffect, useState } from 'react'

/**
 * Hook to determine if large screen format can be used
 */
const useIsFullScreenWeb = () => {
  /**
   * useDimenions from react native will cause a rerender every time
   * the screen changes. This hook is used very heavily, so that causes
   * the performance to suffer. Instead, we can use a setState and only
   * change it when we cross the breakpoint.
   */
  const [isFullScreen, setIsFullScreen] = useState(
    Dimensions.get('window').width >= FULLSCREEN_WIDTH
  )

  useEffect(() => {
    const handler = ({ window }) => {
      setIsFullScreen(window.width >= FULLSCREEN_WIDTH)
    }

    Dimensions.addEventListener('change', handler)

    return () => Dimensions.removeEventListener('change', handler)
  }, [])

  const isWeb = Platform.OS === 'web'

  return isFullScreen && isWeb
}

export default useIsFullScreenWeb
