import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const query = gql`
  query getShareableMedia($id: String!) {
    getShareableMedia(id: $id) {
      id
      title
      subtitle
      description
      topics {
        id
        name
        slug
      }
      image {
        id
        cloudinaryId
      }
      hostMedia {
        ...hostMediaFragment
      }
      highlightsHostMedia {
        ...hostMediaFragment
      }
      personTags {
        person {
          id
          firstName
          lastName
          bio
          photo {
            id
            cloudinaryId
          }
        }
      }
    }
  }
  fragment hostMediaFragment on HostMedia {
    id
    type
    duration
    url
    height
    width
    transcode
  }
`

const useMediaItemQuery = (id: string) => {
  const { data, loading } = useQuery(query, {
    variables: { id },
    skip: !id
  })

  return {
    media: data?.getShareableMedia,
    loading
  }
}

export default useMediaItemQuery
