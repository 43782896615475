import React from 'react'
import { View } from 'react-native'
import { Svg, Path, Defs, Stop, RadialGradient } from 'react-native-svg'

interface MediaDetailBgGradientProps {
  width: number
  height: number
  style?: object | object[]
}

const MediaDetailBgGradient = React.memo(
  ({ width, height, style, ...rest }: MediaDetailBgGradientProps) => {
    return (
      <View pointerEvents="none" style={style}>
        <Svg
          width={width}
          height={height}
          viewBox="0 0 165 45"
          fill="none"
          {...rest}
        >
          <Path fill="url(#paint0_radial)" d="M0 0H165V45H0z" />
          <Defs>
            <RadialGradient
              id="paint0_radial"
              cx={0}
              cy={0}
              r={1}
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(5.30355 -36.1184 131.79177 19.35204 48.321 43.224)"
            >
              <Stop offset={0.146505} stopOpacity={0.91} />
              <Stop offset={0.869001} stopOpacity={0} />
            </RadialGradient>
          </Defs>
        </Svg>
      </View>
    )
  }
)

export default MediaDetailBgGradient
