import React from 'react'

import styled from 'styled-components/native'

import * as Text from '../../../components/core/text'
import PrimaryButton from '../../../components/PrimaryButton'
import NewDownloadIcon from '../../../components/icon/NewDownloadIcon'

const DownloadPrimaryButton = styled(PrimaryButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${({ isFullScreenWeb, theme: { colors, space, sizes } }) => ` 
    height: ${space[4] + 8}px;
    border-radius: ${space[4] - 2}px;
    padding: ${space[3] - 4}px ${space[4] - 2}px;
    margin-top: ${isFullScreenWeb ? space[4] : space[3]}px;
    margin-left: ${space[4]}px;
    margin-bottom: ${isFullScreenWeb ? space[2] : space[1]}px;
    background-color: ${colors.buttonBackground};
    max-width: ${isFullScreenWeb ? '20%' : `${sizes[7] - sizes[5]}px`};
  `}
`

const DownloadText = styled(Text.Subtext)`
  ${({ theme: { fontSizes, fontWeights } }) => ` 
    color: white;
    font-size: ${fontSizes[3]}px;
    font-weight: ${fontWeights.regular};
    line-height: 14px;
    font-family: 'Inter';
  `}
`

const DownloadIcon = styled(NewDownloadIcon).attrs(({ theme: { colors } }) => ({
  color: colors.white,
  size: 14
}))`
  margin: ${({ theme: { space } }) => space[1]}px;
`

interface DownloadButtonProps {
  text: string
  label: string
  onClick: () => unknown
  isFullScreenWeb?: boolean
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  onClick,
  text,
  isFullScreenWeb
}) => (
  <DownloadPrimaryButton
    onPress={onClick}
    isFullScreenWeb={isFullScreenWeb}
    contentStyle={{ width: '100%', justifyContent: 'center' }}
  >
    <DownloadIcon />
    <DownloadText>{text}</DownloadText>
  </DownloadPrimaryButton>
)

export default DownloadButton
