import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { createStackNavigator } from '@react-navigation/stack'
import MediaItemScreen from '../../screens/media/MediaItemScreen'
import DocumentItemScreen from '../../screens/document/DocumentItemScreen'
import ComingSoon from '../../screens/ComingSoonScreen'
import AppHeaderContainer from '../../components/layout/AppHeaderContainer'

const AppViewWrapper = styled(View)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`
const AppViewScrollArea = styled(View)`
  flex: 1;
`

const Stack = createStackNavigator()

const AppNavigator = () => {
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    requestAnimationFrame(() => {
      setIsReady(true)
    })
  }, [])

  return (
    <AppViewWrapper>
      <AppHeaderContainer />
      {isReady && (
        <AppViewScrollArea>
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name="ComingSoon" component={ComingSoon} />
            <Stack.Screen name="MediaItem" component={MediaItemScreen} />
            <Stack.Screen name="DocumentItem" component={DocumentItemScreen} />
          </Stack.Navigator>
        </AppViewScrollArea>
      )}
    </AppViewWrapper>
  )
}

export default AppNavigator
