import React from 'react'
import styled from 'styled-components/native'
import useTranslation from '../../hooks/useTranslation'
import useIsFullScreenWeb from '../../hooks/useIsFullScreenWeb'

const CopyrightContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  ${({ theme: { space } }) => `
    margin-top: ${space[2]}px;
    margin-bottom: ${space[3]}px;
  `}
`

const CopyrightCard = styled.Text`
  ${({ theme: { colors, fontSizes, fontWeights, space } }) => `
    font-weight: ${fontWeights.regular};
    font-size: ${fontSizes[2]}px;
    line-height: ${space[2]}px;
    color: ${colors.copyrightText};
  `}
`

const TextLink = styled.Text`
  text-transform: capitalize;
  ${({ theme: { colors, fontSizes, fontWeights, space } }) => `
    font-weight: ${fontWeights.regular};
    font-size: ${fontSizes[2]}px;
    line-height: ${space[2]}px;
    color: ${colors.copyrightText};
  `}
`

const Copyright: React.FC<CopyrightProps> = () => {
  const { t } = useTranslation()
  const isFullScreenWeb = useIsFullScreenWeb()

  return (
    <CopyrightContainer isFullScreenWeb={isFullScreenWeb}>
      <CopyrightCard>{t('footer:copyright:agreementBeginning')}</CopyrightCard>
      <TextLink
        onPress={() =>
          window.open('https://www.world50.com/legal/terms-of-use')
        }
      >
        {t('footer:copyright:termsLabel')}
      </TextLink>
      <TextLink
        onPress={() =>
          window.open('https://www.world50.com/legal/privacy-policy')
        }
      >
        {t('footer:copyright:privacyLabel')}
      </TextLink>
      <CopyrightCard>{t('footer:copyright:agreementEnd')}</CopyrightCard>
      <TextLink
        onPress={() =>
          window.open(
            'https://www.world50.com/legal/privacy-policy#cookies-and-other-technologies'
          )
        }
      >
        {t('footer:copyright:cookieLabel')}
      </TextLink>
    </CopyrightContainer>
  )
}

interface CopyrightProps {}

export default Copyright
