import React from 'react'
import { Text as ElementsText } from 'react-native-elements'

export const Text = ({ children, styles, ...rest }: TextProps) => {
  return (
    <ElementsText style={styles} {...rest}>
      {children}
    </ElementsText>
  )
}

export const H1 = ({ children, styles }: TextProps) => {
  return (
    <ElementsText style={styles} h1>
      {children}
    </ElementsText>
  )
}

export const H2 = ({ children, styles }: TextProps) => {
  return (
    <ElementsText style={styles} h2>
      {children}
    </ElementsText>
  )
}

export const H3 = ({ children, styles }: TextProps) => {
  return (
    <ElementsText style={styles} h3>
      {children}
    </ElementsText>
  )
}

export const H4 = ({ children, styles }: TextProps) => {
  return (
    <ElementsText style={styles} h4>
      {children}
    </ElementsText>
  )
}

export const Label = ({ children, styles }: TextProps) => {
  return (
    <ElementsText style={styles} h4>
      {children}
    </ElementsText>
  )
}

export interface TextProps {
  children: string | string[]
  styles?: any
}
