import React from 'react'
import { NavigationContainer } from '@react-navigation/native'

import LinkingConfiguration from './LinkingConfiguration'
import RootNavigator from './navigators/RootNavigator'

export default function Navigation() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <RootNavigator />
    </NavigationContainer>
  )
}
