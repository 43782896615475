import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'

const sharedContentRegistration = gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $workEmail: String!
    $companyName: String!
    $jobTitle: String!
    $contentType: ShareableContentTypeEnum!
    $contentId: String!
  ) {
    sharedContentRegistration(
      firstName: $firstName
      lastName: $lastName
      workEmail: $workEmail
      companyName: $companyName
      jobTitle: $jobTitle
      contentType: $contentType
      contentId: $contentId
    ) {
      id
      firstName
      lastName
      workEmail
      companyName
      jobTitle
      userId
    }
  }
`

const useRegistrationMutation = (variables = {}): RegistrationResult => {
  const [registerForm, { loading }] = useMutation(sharedContentRegistration, {
    ...variables,
    fetchPolicy: 'no-cache'
  })
  return { registerForm, loading }
}

interface RegistrationResult {
  registerForm: ({ variables }) => void
  loading: boolean
}

export default useRegistrationMutation
