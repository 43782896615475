import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
// Types
import { ContentTypeEnum } from '../types'

const query = gql`
  query(
    $id: String!
    $contentId: String!
    $contentType: ShareableContentTypeEnum!
  ) {
    canRegistrationViewSharedContent(
      id: $id
      contentId: $contentId
      contentType: $contentType
    )
  }
`

const useCanViewSharedContentQuery = (
  id?: string | null,
  contentId?: string | null,
  contentType?: ContentTypeEnum | null
) => {
  const { data, loading, refetch } = useQuery(query, {
    variables: { id, contentId, contentType },
    skip: !id || !contentId || !contentType,
    notifyOnNetworkStatusChange: true
  })
  return { data, loading, refetch }
}

export default useCanViewSharedContentQuery
