import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import getApiUrl from './utils/getApiUrl'

const setAuthContext = async (headers: any) => {
  const appId = { 'App-Id': 'shared-content' }
  return {
    headers: { ...headers, ...appId }
  }
}

const authLink = setContext((_, { headers }) => setAuthContext(headers))

const httpLink = createHttpLink({
  uri: getApiUrl('http')
})

const wsLink = new WebSocketLink({
  uri: `${getApiUrl('ws')}/graphql`,
  options: {
    reconnect: true,
    lazy: true
  },
  webSocketImpl: WebSocket
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink)
)

const mergeResultsPolicy = {
  fields: {
    hits: {
      merge(existing = [], incoming: any[], { variables }: any) {
        const { offset } = variables
        return offset ? [...existing, ...incoming] : incoming
      }
    }
  }
}

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      SearchResult: mergeResultsPolicy
    }
  }),
  link: splitLink,
  name: 'Shared Content'
})

export default client
