import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  /* Scroll bar stylings */
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #B2B2B2; 
    border-radius: 0px;
    width: 4px
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #404040; 
  }
`
