import React from 'react'
import { TextInput, TextInputProps } from 'react-native'
import styled from 'styled-components/native'

const Input = styled(TextInput)`
  ${({ theme: { space, radii, colors, sizes, fontSizes } }) => `  
    width: 100%;
    flex-wrap: wrap;
    border-width: 1px;
    flex-direction: row;
    align-items: center;
    font-size: ${fontSizes[3] + 2}px;
    background-color: ${colors.gray8};
    border-radius: ${radii[3] + 2}px;
    border-color: ${colors.borderColor};
    padding-left: ${space[2]}px;
    padding-right: ${space[4] + 3}px;
    padding-vertical: ${space[2] + 2}px;
    max-height: ${sizes[6]}px;
    max-width: ${sizes[8]}px;
  `}
`

const RegisterInput: React.FC<RegisterInputProps> = ({
  textInputProps = {}
}) => {
  // Props
  const inputProps: TextInputProps = {
    ...textInputProps,
    autoCapitalize: 'none',
    keyboardAppearance: 'dark',
    multiline: false,
    blurOnSubmit: true
  }

  return <Input {...inputProps} />
}

interface RegisterInputProps {
  textInputProps?: TextInputProps
}

export default RegisterInput
