import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components/native'
import { Flex } from '../components/FlexBox'
import { H2 } from '../components/common/Text'
import useTranslation from '../hooks/useTranslation'
import Logo from '../images/logo.svg'

export default function ComingSoonScreen() {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext) as any

  return (
    <Flex p={50} justifyContent="center" alignItems="center">
      <Logo
        height={100}
        width={100}
        style={{ marginBottom: 20 }}
        color1={theme.colors.logo_onAppBackground}
      />
      <H2>{t('comingSoon:title')}</H2>
    </Flex>
  )
}
