import React, { useEffect } from 'react'
import Constants from 'expo-constants'
import * as Sentry from 'sentry-expo'
import useCachedResources from './src/hooks/useCachedResources'
import AppRoot from './src/AppRoot'
// @ts-ignore
import pkg from './package.json'

if (Constants?.manifest?.extra?.sentry?.enabled) {
  Sentry.init({
    dsn: Constants?.manifest?.extra?.sentry?.dsn,
    environment: Constants?.manifest?.extra?.sentry?.environment,
    release: pkg.version,
    enableInExpoDevelopment:
      Constants?.manifest?.extra?.BUILD_ENV === 'development'
  })
}

export default function App() {
  const isLoadingComplete = useCachedResources()

  useEffect(() => {
    document.addEventListener('contextmenu', event => event.preventDefault())
  }, [])

  if (!isLoadingComplete) {
    return null
  } else {
    return <AppRoot />
  }
}
