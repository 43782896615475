import React from 'react'
import styled from 'styled-components/native'
// Components
import MediaPlayer from './MediaPlayer'
import MetaDataOverlay from './MetaDataOverlay'
import PlayButtonIcon from '../icon/PlayButtonIcon'
import { Hero, HeroPlaceholder } from './VideoBase'
import { HostMediaI, HostMediaData, HeroImageMetaData } from './types'

const PLAY_BUTTON_SIZE = 64
const TOUCHABLE_AREA_PADDING = 20

const MediaMarkerContainer = styled.View`
  width: 100%;
  height: 100%;
`
const MediaHostStyled = styled.View`
  width: 100%;
`
const MediaButtonContainer = styled.View`
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
`
const MediaButton = styled.TouchableOpacity`
  padding: ${TOUCHABLE_AREA_PADDING}px;
  border-radius: ${PLAY_BUTTON_SIZE / 2 + TOUCHABLE_AREA_PADDING}px;
`

interface MediaMarkerProps {
  image?: HeroImageMetaData
  videoMarkers?: HostMediaI
  currentHostMedia?: HostMediaData
}

const MediaMarker: React.FC<MediaMarkerProps> = ({
  image,
  currentHostMedia,
  videoMarkers = {}
}) => {
  const [showPlayer, setShowPlayer] = React.useState(false)

  // Events
  const onPressPlayButton = () => {
    setShowPlayer(true)
  }

  // Renders
  if (!currentHostMedia) {
    return null
  }

  if (showPlayer) {
    return (
      <MediaHostStyled>
        <MediaPlayer
          type={currentHostMedia.type}
          poster={image?.cloudinaryId || ''}
          style={{
            height: videoMarkers.layout.height,
            minHeight: videoMarkers.layout.height,
            width: videoMarkers.layout.width,
            backgroundColor: '#000'
          }}
          source={currentHostMedia.url}
        />
      </MediaHostStyled>
    )
  }

  return (
    <MediaMarkerContainer style={{ ...videoMarkers.layout }}>
      <MediaButtonContainer>
        <MediaButton onPress={onPressPlayButton}>
          <PlayButtonIcon size={PLAY_BUTTON_SIZE} />
        </MediaButton>
      </MediaButtonContainer>
      {image ? (
        <Hero publicId={image.cloudinaryId} resizeMode="cover" />
      ) : (
        <HeroPlaceholder />
      )}
      <MetaDataOverlay
        duration={currentHostMedia.duration}
        type={currentHostMedia.type}
      />
    </MediaMarkerContainer>
  )
}

export default MediaMarker
