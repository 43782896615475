import React from 'react'
import { View } from 'react-native'
import { Svg, Path } from 'react-native-svg'

interface IconProps {
  color: string
  size?: number
  style?: object | object[]
}

const AudioIcon = React.memo(
  ({ color, size = 24, style, ...rest }: IconProps) => (
    <View pointerEvents="none" style={style}>
      <Svg width={size} height={size} viewBox={`0 0 128 128`} {...rest}>
        <Path
          d="M121,76v18c0,3.3-2.7,6-6,6h-4.1v1.5c0,4.1-3.4,7.5-7.5,7.5h-0.9c-4.1,0-7.5-3.4-7.5-7.5v-33c0-4.1,3.4-7.5,7.5-7.5h0.9
        c1.7,0,3.3,0.6,4.6,1.6c-0.1-8.6-1.7-22.3-10.2-32.1C90.5,22,79.2,17.8,64.3,18c-15,0.2-26.4,4.6-33.9,13.3C22.2,41,20.3,54,20,62.5
        c1.3-1,2.8-1.5,4.5-1.5h0.9c4.1,0,7.5,3.4,7.5,7.5v33c0,4.1-3.4,7.5-7.5,7.5h-0.9c-4.1,0-7.5-3.4-7.5-7.5V100H13c-3.3,0-6-2.7-6-6
        V76c0-3,2.3-5.6,5.2-5.9c-0.6-7.3-1-28.6,12.1-43.9c9-10.5,22.4-16,39.9-16.2c17.4-0.2,30.7,4.9,39.6,15.3
        c13.5,15.6,12.5,38,11.8,44.8C118.7,70.4,121,72.9,121,76z"
          fill={color}
        />
      </Svg>
    </View>
  )
)

export default AudioIcon

// <svg version="1.1" id="Layer_1_copy_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
// 	 y="0px" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128;" xml:space="preserve">
// <path d="M121,76v18c0,3.3-2.7,6-6,6h-4.1v1.5c0,4.1-3.4,7.5-7.5,7.5h-0.9c-4.1,0-7.5-3.4-7.5-7.5v-33c0-4.1,3.4-7.5,7.5-7.5h0.9
// 	c1.7,0,3.3,0.6,4.6,1.6c-0.1-8.6-1.7-22.3-10.2-32.1C90.5,22,79.2,17.8,64.3,18c-15,0.2-26.4,4.6-33.9,13.3C22.2,41,20.3,54,20,62.5
// 	c1.3-1,2.8-1.5,4.5-1.5h0.9c4.1,0,7.5,3.4,7.5,7.5v33c0,4.1-3.4,7.5-7.5,7.5h-0.9c-4.1,0-7.5-3.4-7.5-7.5V100H13c-3.3,0-6-2.7-6-6
// 	V76c0-3,2.3-5.6,5.2-5.9c-0.6-7.3-1-28.6,12.1-43.9c9-10.5,22.4-16,39.9-16.2c17.4-0.2,30.7,4.9,39.6,15.3
// 	c13.5,15.6,12.5,38,11.8,44.8C118.7,70.4,121,72.9,121,76z"/>
// </svg>
