import getFileFromUrl from '../../../utils/getFileFromUrl'

const downloadFile = (url: string, fileName: string) => {
  return getFileFromUrl(url, fileName).then(blob => {
    const url = window.URL.createObjectURL(new Blob([blob]))

    const link = document.createElement('a')

    link.href = url
    link.setAttribute('download', fileName)

    document.body.appendChild(link)

    link.click()

    link?.parentNode!.removeChild(link)
  })
}

export default downloadFile
