import React from 'react'
import { sortBy } from 'ramda'
import styled from 'styled-components/native'

import { ContentTopic } from './ContentTopic'
import { TextTopicPill, TopicText } from './TopicPills'

const FooterContainer = styled.View`
  ${({ theme: { space }, wrapPills }) => `
  display: flex;
  flex-wrap: ${wrapPills ? 'wrap' : 'nowrap'};
  flex-direction: row;
  margin-top: ${space[0]}px;
`}
`

const EntityTopicFooter = ({ topics, ...rest }: EntityTopicFooterProps) => {
  if (topics.length > 0) {
    return (
      <FooterContainer {...rest}>
        <TopicText>
          {'Topics: '}
          {sortBy(t => t.name.toLowerCase(), topics).map((topic, i) => (
            <TextTopicPill
              topic={topic}
              key={topic.id}
              islastTopic={topics.length === i + 1}
            />
          ))}
        </TopicText>
      </FooterContainer>
    )
  } else {
    return null
  }
}

export interface EntityTopicFooterProps {
  topics: ContentTopic[]
  wrapPills?: boolean
}

export default EntityTopicFooter
