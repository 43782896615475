import React from 'react'
import Navigation from '../navigation'
import Providers from './providers'

const AppRoot = () => {
  return (
    <Providers>
      <Navigation />
    </Providers>
  )
}

export default AppRoot
