export enum MediaCategoryEnum {
  EngageCalls = 'engageCalls',
  HuddleCalls = 'huddleCalls',
  ExecutiveSummaries = 'executiveSummaries',
  Compilations = 'compilations',
  SummitHighlights = 'summitHighlights'
}

export enum MediaTypeEnum {
  Audio = 'audio',
  Video = 'video'
}

export enum MediaHostEnum {
  Vimeo = 'vimeo',
  Cloudinary = 'cloudinary'
}

export enum DocumentTypeEnum {
  Agenda = 'agenda',
  BriefingBook = 'briefingBook',
  Highlights = 'highlights',
  Insights = 'insights',
  DeepDive = 'deepDive',
  EngageHighlights = 'engageHighlights',
  EvergreenContent = 'evergreenContent',
  OneDegree = 'oneDegree',
  PodcastHighlights = 'podcastHighlights',
  ProgramGuide = 'programGuide',
  Recap = 'recap',
  RoundtableHighlights = 'roundtableHighlights',
  TheW = 'theW',
  Next = 'next',
  Notebook = 'notebook',
  Playbook = 'playbook',
  SharedFile = 'sharedFile'
}

export type GenericObject = {
  [key: string]: any
}

export type RootStackParamList = {
  MediaItem: { id: string }
  DocumentItem: undefined
  ComingSoon: undefined
}

export enum ContentTypeEnum {
  document = 'document',
  media = 'media'
}
