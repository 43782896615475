import { ViewStyle } from 'react-native'
export interface HeroImageMetaData {
  id?: string
  cloudinaryId?: string
}
export interface HostMediaData {
  id: string
  type: string
  url: string
  width?: number | string
  height?: number | string
  duration: number
}
export enum MediaVersion {
  Main = 'main',
  Highlights = 'highlights'
}
export interface MediaMetaData {
  category?: string
  description?: string
  duration?: number
  hostMedias: VersionMap<HostMediaData | undefined>
  id: string | undefined
  image?: HeroImageMetaData | undefined
  publishDate?: string | undefined
  subtitle?: string
  title: string
}

export enum MediaTypes {
  Audio = 'audio',
  Video = 'video'
}

export type VersionMap<T> = {
  [key in MediaVersion]: T
}
export interface HostMediaI {
  id?: number
  layout?: ViewStyle
}
