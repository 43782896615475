import React from 'react'
/* eslint-disable no-unused-vars */
import {
  StyleProp,
  TouchableHighlight,
  TouchableHighlightProps,
  View,
  ViewStyle
} from 'react-native'
import { darken } from 'polished'
import styled from 'styled-components/native'
import { Label } from './core/text'

interface PrimaryButtonProps extends TouchableHighlightProps {
  children?: Element | JSX.Element
  label?: string
  labelProps?: any
  style?: any
  theme?: any
  color?: any
  underlayColor?: any
  contentStyle?: StyleProp<ViewStyle>
}

export const PrimaryButtonContainer = styled(TouchableHighlight).attrs(
  ({ underlayColor, theme: { colors } }) => ({
    underlayColor: underlayColor || darken(0.05, colors.background)
  })
)`
  ${({ theme: { space, colors, radii } }) => `
  height: ${space[4]}px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${space[1]}px;
  background-color: ${colors.primary};
  border-radius: ${radii[4]}px;
`}
`

export const StyledButtonContent = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const PrimaryButton = React.memo(
  ({
    children,
    label,
    labelProps = {},
    contentStyle,
    ...rest
  }: PrimaryButtonProps) => (
    <PrimaryButtonContainer {...rest}>
      <StyledButtonContent style={contentStyle}>
        {children || <Label labelProps={labelProps}>{label || ''}</Label>}
      </StyledButtonContent>
    </PrimaryButtonContainer>
  )
)

export { PrimaryButtonProps }

export default PrimaryButton
